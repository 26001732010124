import { CSV_COLUMNS, BULK_UPDATE_RECORD_LIMIT } from "../constants";
import getKeysFromEnum from "./getKeysFromEnums";

//TODO: Pull from consts
const letterNumberID = /^[0-9a-zA-Z_-]*$/;

const checkHeaders = (headerRow: any) => {
  const columns = getKeysFromEnum(CSV_COLUMNS);
  if (columns.some((key) => !headerRow[CSV_COLUMNS[key]].includes(key))) {
    throw new Error("Headers should be in correct order");
  }
};

const checkForDuplicateRecords = (records: any) => {
  const [_, ...deviceRecords] = records;
  const deviceIds = deviceRecords.map(
    (record: any) => record[CSV_COLUMNS.device_id]
  );

  if (new Set(deviceIds).size !== deviceIds.length) {
    throw new Error("Duplicate records found in CSV");
  }
};

const validators = {
  [CSV_COLUMNS.device_id]: (record: any, index: number) => {
    const deviceId = record[CSV_COLUMNS.device_id];
    if (!letterNumberID.test(deviceId) || deviceId.length > 64) {
      throw new Error(
        `Invalid Device ID at row ${index}. (only 0-9,A-Z,a-z,_,- allowed upto 64 characters)`
      );
    }
  },
  [CSV_COLUMNS.tags]: (record: any, index: number) => {
    try {
      JSON.parse(record[CSV_COLUMNS.tags]);
    } catch (error) {
      throw new Error(`Invalid tag at row ${index}. Value is not a JSON.`);
    }
    return "";
  },
};

const getValidator = (columnName: string) => {
  const columnId = CSV_COLUMNS[columnName];
  return validators[columnId] || (() => true);
};

const validateColumns = (sanitizedData: any) => {
  const columns = getKeysFromEnum(CSV_COLUMNS);
  //will start from index 1 to skip header
  for (let i = 1; i < sanitizedData.length; i++) {
    const record = sanitizedData[i];
    columns.forEach((column: any) => {
      const validator = getValidator(column);
      validator(record, i + 1);
    });
  }
};

const validateData = (sanitizedData: any) => {
  const totalReacords = sanitizedData.length - 1;
  if (totalReacords > BULK_UPDATE_RECORD_LIMIT) {
    throw new Error(
      `Maximum Record Count (${BULK_UPDATE_RECORD_LIMIT}) Exceeded`
    );
  }
  checkHeaders(sanitizedData[0]);
  checkForDuplicateRecords(sanitizedData);
  validateColumns(sanitizedData);
};

export default validateData;
