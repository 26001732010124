import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  WithStyles,
  FormControlLabel,
  Backdrop,
  ThemeProvider,
  createMuiTheme,
  LinearProgress,
  MenuItem,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { useSnackbar } from "notistack";
import Checkbox from "@material-ui/core/Checkbox";
import { API } from "../../../../api/property";
import Dropzone, { useDropzone } from "react-dropzone";
import AddIcon from "@material-ui/icons/Add";
import DocumentIcon from "@material-ui/icons/PostAdd";
import theme from "../../../Theme";
// import csv from "csv-parse";
import callXhrRequest from "../../../../utils/xhrRequestHandler";
import {
  arrayOfErrorsRefresh,
  arrayOfErrorsLogout,
} from "../../../../utils/helper";
import { useStore } from "mobx-store-provider";
import { AuthType } from "../../../../utils/helper";
import { DEFAULT_INSTALL_STATUS_VALUE } from "../../../../utils/constant";
import validateImportedTags from "./validateImportedTags/validateImportedTags";
const border_style = "1px solid #464659";
const border_style2 = "1px solid rgba(235,235,245,0.35)";
const border_box = "border-box";
const alt_label_cover = "red !important";
const font = "Avenir Next";

const blueColor = "#0089FF";
const theme1 = createMuiTheme({
  palette: {
    primary: {
      main: `${blueColor}`,
    },
    secondary: {
      main: `#FFFFFF`,
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropButtonText: {
      color: "#0089FF",
      flex: "none",
      fontSize: "14px",
      alignSelf: "center",
      margin: "2px 0px",
    },
    draganddrop: {
      border: "1px solid #464659",
      boxSizing: "border-box",
      borderRadius: "2px",
      padding: "20px 20px 20px 20px",
    },
    bulkupload: {
      marginTop: "1em",
      marginBottom: "1em",
      marginLeft: "10px",
    },

    uploadText: {
      fontSize: "16px",
      lineHeight: "19px",
      display: "flex",
      alignItems: "center",
      textTransform: "uppercase",
      color: "#FCFCFC",
    },
    files: {
      border: "1px solid #7C7C94",
      boxSizing: "border-box",
      borderRadius: "2px",
      flex: "none",
      fontSize: "14px",
      alignSelf: "center",
      margin: "2px 0px",
      padding: "10px",
    },
    files1: {
      boxSizing: "border-box",
      borderRadius: "2px",
      flex: "none",
      fontSize: "14px",
      alignSelf: "center",
      margin: "2px 0px",
      padding: "10px",
    },
    dropElement: {
      marginTop: "10px",
      marginBottom: "10px",
      marginLeft: "10px",
      marginRight: "10px",
    },
    root: {
      flexGrow: 1,
      margin: "30px",
    },
    noSpace: {
      padding: "0px",
      margin: "0px",
    },
    spaceTop: {
      marginTop: "35px",
    },
    space2Top: {
      marginTop: "20px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    spaceLeft: {
      paddingLeft: "10px",
    },
    menuButtonRoot: {
      padding: "0px",
    },
    formTitle: {
      color: "#FCFCFC",

      fontWeight: "bold",
      fontSize: "21px",
      lineHeight: "25px",
    },
    subtitle: {
      color: "white",
      fontSize: "12px",
      lineHeight: "14px",
      marginTop: "23px",
    },
    form: {
      width: "100%",
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
    labels1: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
      marginTop: "1em",
      marginBottom: "1em",
    },
    gridRoot: {
      flexGrow: 1,
      padding: "0.5em 24px",
      backgroundColor: "#2B2B36",
    },
    noSpaceNoMin: {
      margin: "0px !important",
      padding: "0px !important",
      minHeight: "auto !important",
    },
    typoRoot: {
      paddingLeft: "24px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "14px",
      lineHeight: "16px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: border_style,
      borderRadius: "2px",
      boxSizing: border_box,
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
    },
    fieldRoot: {
      marginTop: "16px",
    },
    selectRoot: {
      padding: "24px",
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "20px",
    },
    btnCancel: {
      borderRadius: "2px",
      border: border_style2,
      color: "#0089FF",
      background: "none",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    btnAddNDevice: {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#0089ff",
      },
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    vp: {
      backgroundColor: "#2B2B36",
      border: border_style,
      borderRadius: "2px",
      color: "#FCFCFC",
      "&:hover": {
        backgroundColor: "#2B2B36",
      },
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "none",
      padding: "12px 0px",
      marginTop: "34px",
      width: "100%",
    },
    buttonText: {
      color: "#0089FF",
      margin: "1em",
      textTransform: "none",
      fontSize: "14px",
    },
    addNewFieldText: {
      color: "#0089FF",
      marginTop: "1em",
      marginBottom: "1em",
      textTransform: "none",
      fontSize: "16px",
      lineHeight: "19px",
    },
    helperfield: {
      color: "#FCFCFC",
      fontSize: "12px",
      lineHeight: "14px",
      padding: "10px",
    },
    textfield1: {
      color: "#FCFCFC",
      border: `1px solid #464659`,
      boxSizing: border_box,
      borderRadius: "2px",
      backgroundColor: "#2B2B36",
    },
    dropButton: {
      textTransform: "none",
      fontSize: "16px",
      margin: "0px",
      border: "1px solid #6E6E79",
      boxSizing: border_box,
      borderRadius: "4px",
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      minWidth: "800px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style2,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRootSmall: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style2,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },

    items: {
      color: "#FCFCFC",
      marginLeft: "2em",
      marginRight: "2em",
      marginTop: "1em",
      marginBottom: "1em",
    },
    dividerColor: {
      background: "#464659",
    },
    space2TopnBottom: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    stepperRoot: {
      width: "50%",
      margin: "0 auto",
    },
    stepname: {
      color: "#FFF",
      "& span": {
        display: "flex",
        flexDirection: "column",
      },
    },
    stepactive: {
      color: "#FFF",
    },
    steplabel: {
      color: "#FFF",
      "& span": {
        color: "darkgray !important",
      },
      "& .active": {
        color: "green !important",
      },
    },
    step: {
      "&$completed": {
        color: "#0089ff",
      },
      "&$active": {
        color: "cornflowerblue",
      },
      "&$disabled": {
        color: "red",
      },
    },
    alternativeLabel: {
      color: "yellow !important",
      "&$active": {
        color: alt_label_cover,
      },
    },
    active: {
      color: "cornflowerblue !important",
    },
    completed: {
      color: "#0089ff !important",
    },
    disabled: {
      color: "red",
    },
    labelContainer: {
      color: alt_label_cover,
      "&$alternativeLabel": {
        marginTop: 0,
        color: "orange !important",
      },
      "&$active": {
        color: alt_label_cover,
      },
      "&$completed": {
        color: "green !important",
      },
    },
    hidden: {
      display: "none",
    },
    appContainer: {
      padding: "1rem",
      background: "#2b2b36",
      marginTop: "1rem",
    },
    colorWhite: {
      color: "#FFFFFF",
    },
    appTitle: {
      width: "100%",
    },
    tableWrapper: {
      marginTop: "15px",
      boxShadow: "none",
      overflow: "hidden",
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    textField: {
      width: "16ch",
      color: "#fff",
      padding: "3px 10px",
      border: "1px solid gray",
    },
    margin: {
      margin: theme.spacing(1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    bgColorBlue: {
      color: "#1976d2 !important",
    },
    checkboxLabel: {
      fontFamily: font,
      fontSize: "16px",
      lineHeight: "28px",
      color: "#FFFFFF",
    },
    checkboxRoot: {
      marginTop: "2em",
      marginBottom: "1em",
      maxWidth: "18em",
    },
    gridVar: {
      fontFamily: font,
      fontSize: "16px",
      fontWeight: "bold",
      lineHeight: "19px",
      color: "#FFFFFF",
      marginTop: "10px",
      marginLeft: "20px",
      marginRight: "20px",
      marginBottom: "10px",
    },
    gridVal: {
      fontFamily: font,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#FFFFFF",
      marginTop: "10px",
      marginLeft: "20px",
      marginBottom: "10px",
      marginRight: "20px",
    },
    topGrid: {
      height: "89px",
      marginLeft: "0px",
      marginRight: "2px",
      marginTop: "1em",
      marginBottom: "1em",
      background: "#2B2B36",
      borderRadius: "4px",
    },
    topGrid1: {
      marginTop: "1em",
      paddingLeft: "30px",
      paddingRight: "30px",
      paddingBottom: "30px",
      background: "#2B2B36",
      borderRadius: "4px",
    },
  })
);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
  });

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const BULK_UPLOAD_RECORD_LIMIT = 1000;

const removeEmptyRecords = (data: any[][]) =>
  (data || []).filter((record) => record.length > 1);

const AddNewDeviceWrapper: any = (props: any) => {
  const classes = useStyles();
  const { user } = useStore();

  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;

  const letterNumber = /^[0-9a-zA-Z_-]+$/;
  const letterNumberID = /^[0-9a-zA-Z_-]*$/;
  const tthex = /^[0-9a-fA-F]{32}$/;
  const sthex = /^[0-9a-fA-F]{16}$/;

  const [configs, setConfigs] = React.useState([] as any);
  const [connConfigs, setConnConfigs] = React.useState([] as any);
  const [reload, setReload] = useState(false);
  const [backDrop, setbackDrop] = React.useState(false);
  const [connConfig, setConnConfig] = React.useState("");
  const [selectedConnConfig, setSelectedConnConfig] = React.useState({} as any);

  const handleChangeConnConfig = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConnConfig(event.target.value);
    connConfigs.forEach((config: any) => {
      if (config.conn_config_id === event.target.value) {
        setSelectedConnConfig(config);
      }
    });
  };

  const [checked, setChecked] = React.useState(false as any);
  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setConnConfig("");
      setSelectedConnConfig({});
    }
    setChecked(!checked);
  };

  const [application, setApplication] = React.useState([] as any);
  const [config, setConfig] = React.useState("" as any);
  const [app, setApp] = React.useState("" as any);
  const [installationStatusOptions, setInstallationStatusOptions] =
    React.useState([]);
  const handleChangeApp = (event: React.ChangeEvent<HTMLInputElement>) => {
    const levels = [] as any;
    application.forEach((app: any) => {
      if (app.application_id === event.target.value) {
        setApp(app.application_id);
        setConfigs(app.device_configs);
        setConnConfigs(app.conn_configs);
        setConfig("");
        setAppLevels(app.application_level);

        setInstallationStatusOptions(
          (app.install_status ? app.install_status : []).map(
            ({
              device_install_status_id: value,
              device_installation_status: label,
            }: any) => ({ value, label })
          )
        );
        setInstallationStatus(
          app.install_status
            ? app.install_status[0]?.device_install_status_id
            : DEFAULT_INSTALL_STATUS_VALUE
        );
      }
    });
  };
  const [selectedConfig, setSelectedConfig] = React.useState({} as any);

  const hasX509AuthType = (inputCongif: Number) => {
    return configs.some(
      (config: any) =>
        config.device_config_id === inputCongif &&
        config.auth_type === AuthType.x509
    );
  };

  const handleChangeConfig = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasX509AuthType(Number(event.target.value))) {
      deviceConficApi(event.target.value);
    } else {
      enqueueSnackbar("BulkUpload for X509 not Allowed.", { variant: "error" });
    }
  };

  const deviceConficApi = (confVal: any) => {
    setConfig(confVal);
    setbackDrop(true);
    setIotHubName([]);
    interface sessionObj {
      pm_s_id: string;
    }
    interface tokenObj {
      pm_s_token: string;
    }
    let pmSessionId: sessionObj = {
      pm_s_id: "",
    };
    let pmSessionToken: tokenObj = {
      pm_s_token: "",
    };
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));

    configs.forEach((config: any) => {
      if (config.device_config_id === confVal) {
        setSelectedConfig(config);
      }
    });
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const GET_DEVICES_CONFIG = API["GETALLAPPLS"];
    Promise.all([
      fetch(
        GET_DEVICES_CONFIG +
          `/${app}/device-config/${confVal}?pm_s_id=${pmSessionId}&pm_s_token=${pmSessionToken}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.access_token}`,
            "Ocp-Apim-Subscription-Key": `${API_KEY}`,
            "Ocp-Apim-Trace": `true`,
          },
        }
      ),
    ])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (response: any) {
        if (
          response &&
          response[0].status === 401 &&
          response &&
          arrayOfErrorsRefresh.includes(response[0].message.toLowerCase())
        ) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest()
              .then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                deviceConficApi(confVal);
                setbackDrop(false);
              })
              .catch(function (error) {
                user.triggerLogout();
                console.log("error", error);
                setbackDrop(false);
              });
          } catch (error) {
            console.log(error);
            setbackDrop(false);
          }
          return;
        } else if (
          response &&
          response[0].status === 401 &&
          response &&
          arrayOfErrorsLogout.includes(response[0].message.toLowerCase())
        ) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          setbackDrop(false);
          return;
        } else {
          const iot_hubs = [] as any;
          if (response[0].az_iot_hubs) {
            response[0].az_iot_hubs.forEach((hub: any) => {
              iot_hubs.push({
                iot_hub_name: hub.iot_hub_name,
                value: hub.iot_hub_name,
              });
            });
          }
          const NoIoTHub = () => {
            enqueueSnackbar("No IoT Hubs present for this application.", {
              variant: "error",
            });
            setIotHubName([]);
          };
          iot_hubs ? setIotHubName(iot_hubs) : NoIoTHub();
          setbackDrop(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [app_levels, setAppLevels] = useState([] as any);
  const [selected_level, setSelectedLevel] = useState("" as any);
  const onChangeAppLevel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLevel(event.target.value);
  };

  const [iotHubName, setIotHubName] = useState([] as any);
  const [iotHubNameSelect, setIotHubNameSelect] = useState("" as any);
  const onChangeIotHubName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIotHubNameSelect(event.target.value);
  };
  const [installationStatus, setInstallationStatus] = useState("");
  const onChangeInstallationStatus = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInstallationStatus(event.target.value);
  };
  const { enqueueSnackbar } = useSnackbar();
  const backDropClose = () => {
    setbackDrop(false);
  };

  function CSVtoArray(text: any) {
    let p = "",
      row = [""],
      ret = [row],
      i = 0,
      r = 0,
      s = !0,
      l;
    for (l of text) {
      if ('"' === l) {
        if (s && l === p) row[i] += l;
        s = !s;
      } else if ("," === l && s) l = row[++i] = "";
      else if ("\n" === l && s) {
        if ("\r" === p) row[i] = row[i].slice(0, -1);
        row = ret[++r] = [(l = "")];
        i = 0;
      } else row[i] += l;
      p = l;
    }
    return removeEmptyRecords(ret);
  }
  const validateField = () => {
    const letterNumber = /^[0-9a-zA-Z_-]+$/;
    let flag = true;
    if (app === "") {
      enqueueSnackbar("Please select Application", { variant: "error" });
      flag = false;
    }
    if (iotHubNameSelect === "") {
      enqueueSnackbar("Please select IoT Hub", { variant: "error" });
      flag = false;
    }
    if (selected_level === "") {
      enqueueSnackbar("Please select Application Level", { variant: "error" });
      flag = false;
    }
    if (connConfig === "" && checked) {
      enqueueSnackbar("Please select connection configuration", {
        variant: "error",
      });
      flag = false;
    }
    if (file === "") {
      enqueueSnackbar("Please upload .csv file", { variant: "error" });
      flag = false;
    }
    if (flag) {
      onBulkUpload();
    }
  };

  function testJSON(text: any) {
    if (typeof text !== "string") {
      return false;
    }
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  }

  const isValidConnectionRequestPayload = ({ conn_request_payload }: any) => {
    try {
      return Object.keys(conn_request_payload).length > 1; //atleasy on key
    } catch {
      return false;
    }
  };

  const hasConnectioRequestPayloadData = (csvJsonData: any[]) =>
    csvJsonData.some(isValidConnectionRequestPayload);

  const getConnectionRequestMissingRecords = (csvJsonData: any[]) =>
    csvJsonData.filter((data: any) => !isValidConnectionRequestPayload(data));

  const onBulkUpload = () => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    interface sessionObj {
      pm_s_id: string;
    }
    interface tokenObj {
      pm_s_token: string;
    }
    let pmSessionId: sessionObj = {
      pm_s_id: "",
    };
    let pmSessionToken: tokenObj = {
      pm_s_token: "",
    };

    if (hasConnectioRequestPayloadData(excelToJsonData)) {
      const missingRecords =
        getConnectionRequestMissingRecords(excelToJsonData);

      if (missingRecords.length > 0) {
        enqueueSnackbar(
          `Connection Request Payload field missing for ${missingRecords.length} records.`,
          { variant: "error" }
        );
        return false;
      }

      if (!checked) {
        enqueueSnackbar("Please Add a valid Connection Configuration", {
          variant: "error",
        });
        return false;
      }
    } else if (checked) {
      enqueueSnackbar(
        `Your CSV does not contain 'conn_request_payload' for any records but you have selected a Connection Configuration.`,
        { variant: "error", autoHideDuration: 8000 }
      );
      return false;
    }

    const tagValidationError = validateImportedTags(
      excelToJsonData,
      selectedConfig
    );

    if (tagValidationError) {
      enqueueSnackbar(tagValidationError, { variant: "error" });
      return false;
    }
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));

    const DEVICES_API = `${API["GETALLAPPLS"]}/${app}/devices/bulk`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken.access_token}`,
        "Ocp-Apim-Subscription-Key": `${API_KEY}`,
        "Ocp-Apim-Trace": `true`,
      },
      body: checked
        ? JSON.stringify({
            pm_s_id: pmSessionId,
            pm_s_token: pmSessionToken,
            devices: excelToJsonData,
            common_metadata: {
              device_config_id: config,
              cloud_gateway: iotHubNameSelect,
              application_level: selected_level,
              device_install_status_id: installationStatus,
              device_config: {
                config_name: selectedConfig.config_name,
                config_desc: selectedConfig.config_type,
                device_type: selectedConfig.device_type,
                auth_type: selectedConfig.auth_type,
                edge_type: selectedConfig.edge_type,
                add_to_iot_hub: "y",
              },
              conn_config_id: connConfig,
              conn_config: selectedConnConfig,
            },
          })
        : JSON.stringify({
            pm_s_id: pmSessionId,
            pm_s_token: pmSessionToken,
            devices: excelToJsonData,
            common_metadata: {
              device_config_id: config,
              cloud_gateway: iotHubNameSelect,
              application_level: selected_level,
              device_install_status_id: installationStatus,
              device_config: {
                config_name: selectedConfig.config_name,
                config_desc: selectedConfig.config_type,
                device_type: selectedConfig.device_type,
                auth_type: selectedConfig.auth_type,
                edge_type: selectedConfig.edge_type,
                add_to_iot_hub: "y",
              },
            },
          }),
    };

    setbackDrop(true);
    Promise.all([fetch(DEVICES_API, requestOptions)])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (response) {
        if (
          response &&
          response[0].status === 401 &&
          response &&
          arrayOfErrorsRefresh.includes(response[0].message.toLowerCase())
        ) {
          try {
            const tokenKey = "sso_token";
            setbackDrop(true);
            callXhrRequest()
              .then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                setbackDrop(false);
                onBulkUpload();
              })
              .catch(function (error) {
                user.triggerLogout();
                console.log("error", error);
              });
          } catch (error) {
            console.log(error);
          }
          return;
        } else if (
          response &&
          response[0].status === 401 &&
          response &&
          arrayOfErrorsLogout.includes(response[0].message.toLowerCase())
        ) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        } else if (response[0].status === 200 || response[0].status === 201) {
          setbackDrop(false);
          enqueueSnackbar("Devices creation initiated", { variant: "success" });
          cancelDeviceAction();
          let responsetext = "Failed Devices : \n\n";
          if (response[0].data.failed_devices) {
            response[0].data.failed_devices.forEach(
              (element: any, index: number) => {
                responsetext =
                  responsetext +
                  `${index + 1}. Device Id: ${element.device_id}. Reason: ${
                    element.error
                  }. \n\n`;
              }
            );
          }
          if (response[0].data.failed_devices) {
            if (response[0].data.failed_devices.length) {
              alert(responsetext);
            }
          }
        } else {
          enqueueSnackbar(
            "Could not fetch a response. Please check the status on devices home page.",
            { variant: "warning" }
          );
          setbackDrop(false);
          setTimeout(function () {
            cancelDeviceAction();
          }, 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
        enqueueSnackbar(
          "Could not fetch a response. Please check the status on devices home page.",
          { variant: "warning" }
        );
        setbackDrop(false);
        setTimeout(function () {
          cancelDeviceAction();
        }, 5000);
      });
  };

  const cancelDeviceAction = () => {
    setTimeout(function () {
      props.returnHome();
      setConfig("");
      setApp("");
      setFile("");
      setExcelToJsonData([]);
      setFileName(false);
    }, 500);
  };

  useEffect(() => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    interface sessionObj {
      pm_s_id: string;
    }
    interface tokenObj {
      pm_s_token: string;
    }
    let pmSessionId: sessionObj = {
      pm_s_id: "",
    };
    let pmSessionToken: tokenObj = {
      pm_s_token: "",
    };
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));
    const GET_DEVICES_INPUTS = API["GETDEVICESINPUTS"];
    Promise.all([
      fetch(
        GET_DEVICES_INPUTS +
          `?pm_s_id=${pmSessionId}&pm_s_token=${pmSessionToken}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.access_token}`,
            "Ocp-Apim-Subscription-Key": `${API_KEY}`,
            "Ocp-Apim-Trace": `true`,
          },
        }
      ),
    ])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (response: any) {
        if (
          response &&
          response[0].status === 401 &&
          response &&
          arrayOfErrorsRefresh.includes(response[0].message.toLowerCase())
        ) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest()
              .then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                setReload(!reload);
              })
              .catch(function (error) {
                user.triggerLogout();
                console.log("error", error);
              });
          } catch (error) {
            console.log(error);
          }
          return;
        } else if (
          response &&
          response[0].status === 401 &&
          response &&
          arrayOfErrorsLogout.includes(response[0].message.toLowerCase())
        ) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        setApplication(response && response[0].devices_input[0].applications);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [API_KEY, reload]);

  const [file, setFile] = React.useState("");
  const [fileName, setFileName] = useState(false);
  const [excelToJsonData, setExcelToJsonData] = useState([] as any);
  const onDrop = React.useCallback((acceptedFiles) => {
    try {
      const reader = new FileReader();
      reader.onabort = () =>
        enqueueSnackbar("File upload aborted", { variant: "error" });
      reader.onerror = (error) =>
        enqueueSnackbar("File upload failed" + error ? error : "", {
          variant: "error",
        });
      reader.onload = async () => {
        const data = CSVtoArray(reader.result as any);
        let flag = true;

        if (data.length - 1 > BULK_UPLOAD_RECORD_LIMIT) {
          //-1 to skip first header row
          setFileName(false);
          setFile("");
          return enqueueSnackbar(
            `Maximum ${BULK_UPLOAD_RECORD_LIMIT} records allowed at a time. Please reduce file size.`,
            { variant: "error" }
          );
        }

        if (
          !data[0][0].includes("device_id") ||
          !data[0][1].includes("device_name") ||
          !data[0][2].includes("properties") ||
          !data[0][3].includes("serial_num") ||
          !data[0][4].includes("tags") ||
          !data[0][5].includes("fields") ||
          !data[0][6].includes("conn_request_payload")
        ) {
          setFileName(false);
          setFile("");
          return enqueueSnackbar(
            "File headers should be in correct order. Please download sample CSV for reference.",
            { variant: "error" }
          );
        } else {
          data.shift();
          let arrayToObject = [] as any;
          await data.map((e: any, index: number) => {
            if (!e[0] && !e[1] && !e[2] && !e[3] && !e[4] && !e[5] && !e[6]) {
              flag = true;
            } else if (!e[0] || !e[1] || !e[3]) {
              enqueueSnackbar(
                `Please enter all mandatory values (id, name, serial) at row ${
                  index + 2
                } of CSV file. `,
                { variant: "error" }
              );
              setFileName(false);
              setFile("");
              flag = false;
            } else if (!(letterNumberID.test(e[0]) && e[0].length <= 64)) {
              enqueueSnackbar(
                `Invalid Device Id at row ${
                  index + 2
                } of CSV file. (only 0-9,A-Z,a-z,_,- allowed upto 64 characters)`,
                { variant: "error" }
              );
              setFileName(false);
              setFile("");
              flag = false;
            } else if (!letterNumber.test(e[1])) {
              enqueueSnackbar(
                `Invalid Name at row ${
                  index + 2
                } of CSV file. (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)`,
                { variant: "error" }
              );
              setFileName(false);
              setFile("");
              flag = false;
            } else if (!letterNumber.test(e[3])) {
              enqueueSnackbar(
                `Invalid Serial at row ${
                  index + 2
                } of CSV file. (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)`,
                { variant: "error" }
              );
              setFileName(false);
              setFile("");
              flag = false;
            } else {
              if (e[6] && !testJSON(e[6])) {
                setFileName(false);
                setFile("");
                flag = false;
                return enqueueSnackbar(
                  `Error in data at row ${
                    index + 2
                  } of CSV file. Connection Config payload is not a valid JSON.`,
                  { variant: "error" }
                );
              } else if (e[2] && !testJSON(e[2])) {
                setFileName(false);
                setFile("");
                flag = false;
                enqueueSnackbar(
                  `Properties column should be a JSON at row ${
                    index + 2
                  } of CSV file. `,
                  { variant: "error" }
                );
              } else if (e[4] && !testJSON(e[4])) {
                setFileName(false);
                setFile("");
                flag = false;
                enqueueSnackbar(
                  `Tags column should be a JSON at row ${
                    index + 2
                  } of CSV file. `,
                  { variant: "error" }
                );
              } else if (e[5] && !testJSON(e[5])) {
                setFileName(false);
                setFile("");
                flag = false;
                enqueueSnackbar(
                  `Fields column should be a JSON at row ${
                    index + 2
                  } of CSV file. `,
                  { variant: "error" }
                );
              } else {
                if (e[4] && testJSON(e[4])) {
                  for (let tag in JSON.parse(e[4])) {
                    if (!(letterNumber.test(tag) && tag.length < 50)) {
                      enqueueSnackbar(
                        `Invalid value for '${tag}' in Tags at row ${
                          index + 2
                        }  (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)`,
                        { variant: "error" }
                      );
                      flag = false;
                      setFileName(false);
                      setFile("");
                    }
                  }
                }
                if (e[4] && testJSON(e[5])) {
                  for (let prop in JSON.parse(e[2])) {
                    if (!(letterNumber.test(prop) && prop.length < 50)) {
                      enqueueSnackbar(
                        `Invalid value for '${prop}' in Properties at row ${
                          index + 2
                        }  (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)`,
                        { variant: "error" }
                      );
                      flag = false;
                      setFileName(false);
                      setFile("");
                    }
                  }
                }
                if (e[6]) {
                  if (!testJSON(e[6])) {
                    enqueueSnackbar(
                      `Connection payload column should be a JSON at row ${
                        index + 2
                      } of CSV file. `,
                      { variant: "error" }
                    );
                    flag = false;
                    setFileName(false);
                    setFile("");
                  } else if (!JSON.parse(e[6]).title) {
                    enqueueSnackbar(
                      `Please enter device title for LORIOT at row ${
                        index + 2
                      }`,
                      { variant: "error" }
                    );
                    flag = false;
                    setFileName(false);
                    setFile("");
                  } else if (!JSON.parse(e[6]).description) {
                    enqueueSnackbar(
                      `Please enter device description for LORIOT at row ${
                        index + 2
                      }`,
                      { variant: "error" }
                    );
                    flag = false;
                    setFileName(false);
                    setFile("");
                  } else if (!JSON.parse(e[6]).devclass) {
                    enqueueSnackbar(
                      `Please select device class at row ${index + 2}`,
                      { variant: "error" }
                    );
                    flag = false;
                    setFileName(false);
                    setFile("");
                  } else if (
                    !JSON.parse(e[6]).appkey ||
                    !tthex.test(JSON.parse(e[6]).appkey)
                  ) {
                    enqueueSnackbar(
                      `Please enter valid App Key (32 character HEX) at row ${
                        index + 2
                      }`,
                      { variant: "error" }
                    );
                    flag = false;
                    setFileName(false);
                    setFile("");
                  } else if (
                    !JSON.parse(e[6]).appeui ||
                    !sthex.test(JSON.parse(e[6]).appeui)
                  ) {
                    enqueueSnackbar(
                      `Please enter valid App EUI (16 character HEX) at row ${
                        index + 2
                      }`,
                      { variant: "error" }
                    );
                    flag = false;
                    setFileName(false);
                    setFile("");
                  } else if (
                    !JSON.parse(e[6]).deveui ||
                    !sthex.test(JSON.parse(e[6]).deveui)
                  ) {
                    enqueueSnackbar(
                      `Please enter valid Dev EUI (16 character HEX) at row ${
                        index + 2
                      }`,
                      { variant: "error" }
                    );
                    flag = false;
                    setFileName(false);
                    setFile("");
                  } else if (
                    //TODO: JSON.parse is redundant. Optimize
                    (JSON.parse(e[6]).deveui || "").toUpperCase() !==
                    (JSON.parse(e[6]).deveui || "")
                  ) {
                    enqueueSnackbar(
                      `Please enter Dev EUI in upper case at row ${index + 2}`,
                      { variant: "error" }
                    );
                    flag = false;
                    setFileName(false);
                    setFile("");
                  }
                }

                if (flag) {
                  arrayToObject.push({
                    device_id: e[0],
                    device_name: e[1],
                    properties: e[2] ? JSON.parse(e[2]) : {},
                    serial_num: e[3],
                    tags: e[4] ? JSON.parse(e[4]) : {},
                    fields: e[5] ? JSON.parse(e[5]) : {},
                    conn_request_payload: e[6] ? JSON.parse(e[6]) : {},
                  });
                }
              }
            }
          });
          if (flag) {
            enqueueSnackbar(`File successfully selected.`, {
              variant: "success",
            });
            setFileName(true);
            setExcelToJsonData(arrayToObject);
          }
        }
      };
      acceptedFiles.forEach((file: any) => reader.readAsBinaryString(file));
    } catch (error) {
      console.log(error);
    }
  }, []);

  useDropzone({ onDrop });

  const downloadExcel = () => {
    const link = document.createElement("a");
    link.download = `Bulk_Upload.csv`;
    link.href = "./Bulk_Upload.csv";
    link.click();
  };
  const deleteCSV = () => {
    setFile("");
    setExcelToJsonData([]);
    setFileName(false);
  };
  return (
    <div className={classes.root}>
      <Typography className={classes.subtitle}>
        All fields marked * are mandatory
      </Typography>

      <Grid
        container
        direction="row"
        spacing={2}
        className={classes.bulkupload}
        xs={12}
        alignItems="center"
        justify="space-between"
      >
        <Grid item className={classes.draganddrop}>
          <Dropzone
            maxFiles={1}
            multiple={false}
            onDrop={async (acceptedFiles) => {
              if (!acceptedFiles[0].name.toLowerCase().endsWith(".csv")) {
                enqueueSnackbar("Only .csv file is allowed.", {
                  variant: "error",
                });
              } else {
                {
                  fileName === true
                    ? setFile("")
                    : setFile(acceptedFiles[0].name);
                }
                onDrop(acceptedFiles);
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid className={classes.bulkupload} item>
                      <Grid container direction="column">
                        <Grid item>
                          <Grid container direction="row" justify="center">
                            <ThemeProvider theme={theme}>
                              <DocumentIcon
                                fontSize="large"
                                color="secondary"
                              ></DocumentIcon>
                            </ThemeProvider>
                          </Grid>
                          <Grid container direction="row" justify="center">
                            <Grid item>
                              <Typography
                                color="secondary"
                                className={classes.uploadText}
                              >
                                DRAG AND DROP A CSV FILE
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" justify="center">
                        <Grid item className={classes.dropElement}>
                          <Typography color="secondary">or</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" justify="center">
                        <Grid item className={classes.dropElement}>
                          <ThemeProvider theme={theme1}>
                            <Button
                              variant="outlined"
                              color="primary"
                              classes={{ outlined: classes.dropButton }}
                            >
                              <Typography className={classes.dropButtonText}>
                                Select a file to upload
                              </Typography>
                              <AddIcon></AddIcon>
                            </Button>
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </section>
            )}
          </Dropzone>
          {file === "" ? (
            <Grid item>
              <div></div>
            </Grid>
          ) : (
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setFile("");
                }}
                classes={{ outlined: classes.dropButton }}
              >
                <Typography color="secondary">{file}</Typography>
                <ClearIcon onClick={deleteCSV}></ClearIcon>
              </Button>
            </Grid>
          )}
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            className={classes.btnAddNDevice}
            onClick={downloadExcel}
          >
            Download sample CSV file
          </Button>
        </Grid>
      </Grid>

      <form className={classes.form} noValidate method="post">
        <div>
          <Grid container>
            <Grid item xs={6} className={classes.spaceRight}>
              <div className={classes.fieldWrapper}>
                <InputLabel
                  classes={{ root: classes.labels }}
                  htmlFor="selectApplication"
                >
                  Select Application *
                </InputLabel>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  select
                  variant="outlined"
                  classes={{ root: classes.fieldRoot }}
                  inputProps={{ className: classes.field }}
                  value={app}
                  id="selectApplication"
                  SelectProps={{
                    classes: {
                      iconOutlined: classes.icon,
                    },
                  }}
                  onChange={handleChangeApp}
                >
                  {application.map((option: any) => (
                    <MenuItem
                      className={classes.options}
                      key={option.application_id}
                      value={option.application_id}
                    >
                      {option.application_name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.spaceLeft}>
              <div className={classes.fieldWrapper}>
                <InputLabel
                  classes={{ root: classes.labels }}
                  htmlFor="Device Configuration"
                >
                  Device Configuration *
                </InputLabel>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  select
                  variant="outlined"
                  classes={{ root: classes.fieldRoot }}
                  inputProps={{ className: classes.field }}
                  value={config}
                  id="selectConfiguration"
                  SelectProps={{
                    classes: {
                      iconOutlined: classes.icon,
                    },
                  }}
                  onChange={handleChangeConfig}
                >
                  {configs
                    ? configs.map((option: any) => (
                        <MenuItem
                          className={classes.options}
                          key={option.device_config_id}
                          value={option.device_config_id}
                        >
                          {option.config_name}
                        </MenuItem>
                      ))
                    : []}
                </TextField>
              </div>
            </Grid>
            {config ? (
              <Grid container md={12} direction="row">
                <Grid
                  container
                  xs={12}
                  direction="row"
                  justify="flex-start"
                  spacing={5}
                  alignItems="center"
                  className={classes.topGrid}
                >
                  <Grid item>
                    <Grid container direction="column" spacing={2}>
                      <Typography className={classes.gridVar}>
                        Device Type
                      </Typography>
                      <Typography className={classes.gridVal}>
                        {selectedConfig.device_type}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container direction="column" spacing={2}>
                      <Typography className={classes.gridVar}>
                        Edge Type
                      </Typography>
                      <Typography className={classes.gridVal}>
                        {selectedConfig.edge_type}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container direction="column" spacing={2}>
                      <Typography className={classes.gridVar}>
                        Authentication Type
                      </Typography>
                      <Typography className={classes.gridVal}>
                        {selectedConfig.auth_type}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6} className={classes.spaceRight}>
                  <div className={classes.fieldWrapper}>
                    <InputLabel
                      classes={{ root: classes.labels }}
                      htmlFor="selectApplication"
                    >
                      IoT Hub Name *
                    </InputLabel>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      select
                      variant="outlined"
                      classes={{ root: classes.fieldRoot }}
                      inputProps={{ className: classes.field }}
                      value={iotHubNameSelect}
                      id="selectApplication"
                      SelectProps={{
                        classes: {
                          iconOutlined: classes.icon,
                        },
                      }}
                      onChange={onChangeIotHubName}
                    >
                      {iotHubName.map((option: any) => (
                        <MenuItem
                          className={classes.options}
                          key={option.iot_hub_dlm_id}
                          value={option.iot_hub_name}
                        >
                          {option.iot_hub_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </Grid>

                <Grid item xs={6} className={classes.spaceLeft}>
                  <div className={classes.fieldWrapper}>
                    <InputLabel
                      classes={{ root: classes.labels }}
                      htmlFor="selectApplicationLevel"
                    >
                      Application Level *
                    </InputLabel>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      select
                      variant="outlined"
                      classes={{ root: classes.fieldRoot }}
                      inputProps={{ className: classes.field }}
                      value={selected_level}
                      id="selectApplicationLevel"
                      SelectProps={{
                        classes: {
                          iconOutlined: classes.icon,
                        },
                      }}
                      onChange={onChangeAppLevel}
                    >
                      {app_levels.map((option: any) => (
                        <MenuItem
                          className={classes.options}
                          key={option.level_id}
                          value={option.level_id}
                        >
                          {option.level_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </Grid>
                {installationStatusOptions.length > 0 && (
                  <Grid item xs={6} className={classes.spaceRight}>
                    <div className={classes.fieldWrapper}>
                      <InputLabel
                        classes={{ root: classes.labels }}
                        htmlFor="installationStatus"
                      >
                        Installation Status *
                      </InputLabel>
                      <TextField
                        className={classes.textfield}
                        fullWidth
                        select
                        variant="outlined"
                        classes={{ root: classes.fieldRoot }}
                        inputProps={{ className: classes.field }}
                        value={installationStatus}
                        id="installationStatus"
                        SelectProps={{
                          classes: {
                            iconOutlined: classes.icon,
                          },
                        }}
                        onChange={onChangeInstallationStatus}
                      >
                        {installationStatusOptions.map((option: any) => (
                          <MenuItem
                            className={classes.options}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </Grid>
                )}
              </Grid>
            ) : (
              <div></div>
            )}
          </Grid>

          <div className={classes.fieldWrapper}>
            <Grid container direction="column">
              <FormControlLabel
                classes={{
                  root: classes.checkboxRoot,
                  label: classes.checkboxLabel,
                }}
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChangeChecked}
                    name="checked"
                    color="primary"
                    className={classes.bgColorBlue}
                  />
                }
                label="Add a Connection Configuration"
              />

              {checked ? (
                <Grid item xs={6} className={classes.spaceRight}>
                  <InputLabel
                    classes={{ root: classes.labels }}
                    htmlFor="ConnConfiguration"
                  >
                    Connection Configuration *
                  </InputLabel>
                  <TextField
                    className={classes.textfield}
                    fullWidth
                    select
                    variant="outlined"
                    classes={{ root: classes.fieldRoot }}
                    inputProps={{ className: classes.field }}
                    value={connConfig}
                    id="selectConfiguration"
                    SelectProps={{
                      classes: {
                        /* selectMenu : classes.selectRoot, */
                        iconOutlined: classes.icon,
                      },
                    }}
                    onChange={handleChangeConnConfig}
                  >
                    {connConfigs
                      ? connConfigs.map((option: any) => (
                          <MenuItem
                            className={classes.options}
                            key={option.conn_config_id}
                            value={option.conn_config_id}
                          >
                            {option.config_name}
                          </MenuItem>
                        ))
                      : []}
                  </TextField>
                </Grid>
              ) : (
                <div></div>
              )}
            </Grid>
          </div>
        </div>
        <div className={`${classes.space2Top} ${classes.bottomSection}`}>
          <Button
            variant="outlined"
            className={classes.btnCancel}
            onClick={cancelDeviceAction}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.btnAddNDevice}
            onClick={validateField}
          >
            Save Device
          </Button>
        </div>
      </form>
      <Backdrop
        className={classes.backdrop}
        open={backDrop}
        onClick={backDropClose}
      >
        <Grid container direction="column" alignItems="center">
          <ThemeProvider theme={theme1}>
            <LinearProgress color="primary" style={{ width: "80%" }} />
          </ThemeProvider>
          <Typography
            style={{
              fontFamily: "Avenir Next",
              fontSize: "14px",
              lineHeight: "16px",
              display: "flex",
              alignItems: "center",
              margin: "1em",
              color: "#0089FF",
            }}
          >
            Please wait. Request is in progress.
          </Typography>
        </Grid>
      </Backdrop>
    </div>
  );
};

export default function AddNewDevice(props: any) {
  return <AddNewDeviceWrapper returnHome={props.returnHome} />;
}
