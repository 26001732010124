import React, { useEffect, useState } from "react";
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  CircularProgress,
  Backdrop,
  Checkbox,
  createMuiTheme,
  Divider,
  FormControlLabel,
  WithStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { SnackbarProvider, useSnackbar } from "notistack";
import { API } from "../../api/property";
import callXhrRequest from "../../utils/xhrRequestHandler";
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from '../../utils/helper';
import { useStore } from "mobx-store-provider";
import BreadcumbComponent from '../../utils/breadcurmb';
const borderCss = "1px solid #464659";
const borderBox = "border-box";
const borderCss2 = "1px solid rgba(235,235,245,0.35)";
const fontFamily = "Avenir Next";
const blueColor = "#0089FF";
const theme1 = createMuiTheme({
  palette: {
    primary: {
      main: `${blueColor}`,
    },
    secondary: {
      main: `#FFFFFF`,
    },
  },
});

const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: borderCss,
      borderRadius: "2px",
      marginTop: "16px",
    },
    "& .MuiInput-multiline": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: "borderCss",
      borderRadius: "2px",
      marginTop: "32px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const CssTextFieldVariable = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: borderCss,
      borderRadius: "2px",
    },
    "& .MuiInput-multiline": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: borderCss,
      borderRadius: "2px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: "30px",
    },
    noSpace: {
      padding: "0px",
      margin: "0px",
    },
    spaceTop: {
      marginTop: "35px",
    },
    space2Top: {
      marginTop: "20px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    spaceLeft: {
      paddingLeft: "10px",
    },
    compactInput: {
      width:'10em',
    },
    gridRoot: {
      flexGrow: 1,
      padding: "0.5em 24px",
      backgroundColor: "#2B2B36",
    },
    formTitle: {
      color: "#FCFCFC",

      fontWeight: "bold",
      fontSize: "21px",
      lineHeight: "25px",
    },
    subtitle: {
      color: "#FFFFFF",
      fontSize: "12px",
      lineHeight: "14px",
      marginTop: "23px",
    },
    form: {
      width: "100%",
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
     },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
    labels1: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
      marginTop: "1em",
      marginBottom: "1em",
    },
    noSpaceNoMin: {
      margin: "0px !important",
      padding: "0px !important",
      minHeight: "auto !important",
    },
    typoRoot: {
      paddingLeft: "24px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "14px",
      lineHeight: "16px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: "borderCss",
      borderRadius: "2px",
      boxSizing: borderBox,
    },

    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
    },
    fieldRoot: {
      marginTop: "16px",
    },
    selectRoot: {
      padding: "24px",
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "20px",
    },
    btnCancel: {
      borderRadius: "2px",
      border: borderCss2,
      color: "#0089FF",
      background: "none",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    btnAddNDevice: {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#0089ff",
      },
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    vp: {
      backgroundColor: "#2B2B36",
      border: "borderCss",
      borderRadius: "2px",
      color: "#FCFCFC",
      "&:hover": {
        backgroundColor: "#2B2B36",
      },
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "none",
      padding: "12px 0px",
      marginTop: "34px",
      width: "100%",
    },
    buttonText: {
      color: "#0089FF",
      margin: "1em",
      textTransform: "none",
      fontSize: "14px",
    },
    addNewFieldText: {
      color: "#0089FF",
      marginTop: "1em",
      marginBottom: "1em",
      textTransform: "none",
      fontSize: "16px",
      lineHeight: "19px",
    },
    helperfield: {
      color: "#FCFCFC",
      fontSize: "12px",
      lineHeight: "14px",
      padding: "10px",
    },
    textfield1: {
      color: "#FCFCFC",
      border: `borderCss`,
      boxSizing: borderBox,
      borderRadius: "2px",
      backgroundColor: "#2B2B36",
    },
    dropButton: {
      textTransform: "none",
      fontSize: "16px",
      margin: "0px",
      border: "1px solid #6E6E79",
      boxSizing: borderBox,
      borderRadius: "4px",
    },
    topGrid: {
      marginTop: "1em",
      paddingLeft: "30px",
      paddingRight: "30px",
      paddingBottom: "30px",
      background: "#2B2B36",
      borderRadius: "4px",
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      minWidth: "800px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: borderCss2,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRootSmall: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: borderCss2,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },

    items: {
      color: "#FCFCFC",
      marginLeft: "2em",
      marginRight: "2em",
      marginTop: "1em",
      marginBottom: "1em",
    },
    dividerColor: {
      background: "#464659",
    },
    checkboxLabel: {
      fontFamily: fontFamily,
      fontSize: "16px",
      lineHeight: "28px",
      color: "#FFFFFF",
    },
    checkboxRoot: {
      marginTop: '2em',
      marginBottom: '1em',
      maxWidth:'18em'
    },
    bgColorBlue: {
      color: "#1976d2 !important",
    },
    addNewFieldContentHolder: {
      overflowY: "auto",
      overflowX: "hidden",
      maxHeight: "550px",
    }
  })
);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
  });
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: "0px 15px",
    color: "#FFFFFF",
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "20px 15px",
    justifyContent: "space-between",
    padding: 0,
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

const AddNewConfigWrapper: any = (props: any) => {
  const classes = useStyles();
  const { user } = useStore();

  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  // const authOptions = [
  //   {
  //     value: "SAS",
  //     label: "SAS",
  //   },
  // ];

  const edgeOptions = [
    {
      value: "NON_EDGE",
      label: "NON_EDGE",
    },
  ];

  const deviceOptions = [
    {
      value: "THING",
      label: "THING",
    },
    {
      value: "GATEWAY",
      label: "GATEWAY",
    },
    {
      value: "STANDALONE",
      label: "STANDALONE",
    },
  ];

  const newFieldOptions = [
    {
      value: "Text",
      label: "Text",
    },
    {
      value: "Dropdown",
      label: "Dropdown",
    },

    {
      value: "Tabular",
      label: "Tabular",
    },
  ];
  const newFieldOptionsCategory = [
    {
      value: "Tag",
      label: "Tag",
    },
    {
      value: "Property",
      label: "Property",
    },
  ];

  const newFieldOptionsDataType = [
    {
      value: "number",
      label: "Number",
    },
    {
      value: "text",
      label: 'Text'
    }
  ]

  const [tags, setTags] = React.useState([] as any);
  const [properties, setProperties] = React.useState([] as any);
  const [open, setOpen] = useState(false);
  const [uApplication, setApplication] = React.useState([] as any);
  const [deviceType, setDeviceType] = useState("" as any);
  const [edgeType, setEdgeType] = useState("NON_EDGE" as any);
  const [reload, setReload] = React.useState(false);
  const [authType, setAuthType] = useState('');
  const [configInputData, setConfigInputData] = useState([]);
  const [authOptions, setAuthOptions] = useState([]);
  const [backDrop, setbackDrop] = React.useState(false);
  const backDropClose = () => {
    setbackDrop(false);
  };
  const [checked, setChecked] = React.useState(true as any);
  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(true);
  };

  const [app, setApp] = React.useState("");
  const handleChangeApp = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApp(event.target.value);
    setAuthType('');
    let authVal: any = [];
    configInputData?.map((x: any)=>(
      x.application_id === event.target.value? x.auth_type.map((y: any) => authVal.push(
        {
          value: y,
          label: y,
        }
      )) : null
    ))
    setAuthOptions(authVal)
  };

  const [openDropdown, setOpenDropdown] = React.useState(false);

  const [dropdowns, setDropdowns] = useState([] as any);
  const [tagDrops, setTagDrops] = React.useState({
    id: "",
    tagName: "",
    fieldName: "",
    value: "",
  });
  const handleChangeTagDrops = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const values = { ...tagDrops };
    values[event.target.name] = event.target.value;
    values["id"] = Date.now().toString();
    setTagDrops(values);
  };
  const handleSubmitNewDropdownFields = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const dropdowns1 = [...dropdowns];

    if (tagDrops.tagName === "" || tagDrops.fieldName === "") {
      enqueueSnackbar(`Please enter all fields`, { variant: "error" });
    } else {
      let flag1 = false;
      tags.forEach((tag: any) => {
        if (tag.fieldName == tagDrops.tagName) {
          flag1 = true;
          let flag2 = false;
          tag.value.split("|").forEach((element: any) => {
            if (element == tagDrops.fieldName) {
              flag2 = true;
            }
          });
          if (!flag2) {
            enqueueSnackbar(
              `No field-name : ${tagDrops.fieldName} has been entered for tag : ${tagDrops.tagName}`,
              { variant: "error" }
            );
          } else {
            dropdowns1.push(tagDrops);
            setDropdowns(dropdowns1);
            setOpenDropdown(false);
            setTagDrops({ id: "", tagName: "", fieldName: "", value: "" });
          }
        }
      });
      if (!flag1) {
        enqueueSnackbar(`No tag added named : ${tagDrops.tagName}`, {
          variant: "error",
        });
      }
    }
  };
  const handleChangeDropdowns = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    const dropdowns1 = [...dropdowns];
    dropdowns1[index]["value"] = event.target.value;
    setDropdowns(dropdowns1);
  };

  const [addFieldInput, setAddFieldInput] = useState([
    {
      id: "",
      fieldName: "",
      fieldType: "",
      value: "",
      category: "",
      dataType:"",
      showAsColumn: true,
      options: [],
    },
  ]);

  const handleChangeAddNewFields = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const values = [...addFieldInput];
    values[index][event.target.name] = event.target.value;
    values[index]["id"] = Date.now().toString();
    setAddFieldInput(values);
  };

  const handleSubmitNewFields = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const letterNumber = /^[0-9a-zA-Z_-]+$/;

    let flag = true;
    const temp = [...addFieldInput];
    temp.forEach((item) => {
      if (item.fieldName === "") {
        enqueueSnackbar("Field Name cannot be empty", { variant: "error" });
        flag = false;
      } else if (!letterNumber.test(item.fieldName)) {
        enqueueSnackbar(
          `Invalid field name for '${item.fieldName}'(only 0-9,A-Z,a-z,_,- allowed with no spaces)`,
          { variant: "error" }
        );
        flag = false;
      } else if (item.fieldType === "") {
        enqueueSnackbar("Field Type cannot be empty", { variant: "error" });
        flag = false;
      } else if(item.dataType === '') {
        enqueueSnackbar("Data Type cannot be empty", { variant: "error" });
        flag = false;
      } else if (item.category === "") {
        enqueueSnackbar("Category cannot be empty", { variant: "error" });
        flag = false;
      } else if (item.category === "Property" && item.fieldType === "Tabular") {
        enqueueSnackbar("Please select 'Text' as Field Type for properties", {
          variant: "error",
        });
        flag = false;
      }
      if (item.category === "Property" && item.fieldType === "Dropdown") {
        enqueueSnackbar("Please select 'Text' as Field Type for properties", {
          variant: "error",
        });
        flag = false;
      }
      if(item.category === "Property" && item.showAsColumn) {
        enqueueSnackbar("Properties cannot be shown as column", { variant: "error" });
        flag = false;
      }
      if(item.category === "Property" && item.dataType === "Number") {
        enqueueSnackbar("Data Type of Properties should always be Text", { variant: "error" });
        flag = false;
      }
      if(item.category === "Tag" && item.fieldType === "Tabular" && item.showAsColumn) {
        enqueueSnackbar("Field Type 'Tabular' cannot be shown as column", { variant:"error" });
        flag = false;
      } 
    });
    if (flag) {
      const tags1 = [...tags];
      const properties1 = [...properties];
      temp.forEach((item) => {
        if (item.category === "Tag") {
          tags1.push(item);
        } else if (item.category === "Property") {
          properties1.push(item);
        }
      });
      setTags(tags1);
      setProperties(properties1);
      setOpen(false);
      setAddFieldInput([
        {
          id: "",
          fieldName: "",
          fieldType: "",
          value: "",
          category: "",
          dataType:"",
          showAsColumn: true,
          options: [],
        },
      ]);
    }
  };
  const handleAddNewFields = () => {
    setAddFieldInput([
      ...addFieldInput,
      {
        id: "",
        fieldName: "",
        fieldType: "",
        value: "",
        category: "",
        dataType:"",
        showAsColumn: true,
        options: [],
      },
    ]);
  };
  const handleDeleteNewFields = (index: number) => {
    const values = [...addFieldInput];
    values.splice(index, 1);
    setAddFieldInput(values);
  };
  const handleChangeTags = (index: number, event: any) => {
    const temp = [...tags];
    temp[index].value = event.target.value;
    setTags(temp);
  };
  const handleChangeProperties = (index: number, event: any) => {
    const temp = [...properties];
    temp[index].value = event.target.value;
    setProperties(temp);
  };
  const [config, setConfig] = React.useState("");
  const handleChangeConfig = (event: any) => {
    setConfig(event.target.value);
  };

  const [configDesc, setConfigDesc] = React.useState("");
  const handleChangeConfigDesc = (event: any) => {
    setConfigDesc(event.target.value);
  };
  const { enqueueSnackbar } = useSnackbar();
  const validateField = () => {
    const letterNumber = /^[0-9a-zA-Z_-\s\.]*$/;
    const letterNumber1 = /^[0-9a-zA-Z_-|\s]*$/;

    let flag = true;
    if (app === "") {
      enqueueSnackbar("Please select Application", { variant: "error" });
      flag = false;
    }
    if (deviceType === "") {
      enqueueSnackbar("Please select Device Type", { variant: "error" });
      flag = false;
    }
    if (config === "") {
      enqueueSnackbar("Please enter configuration name", { variant: "error" });
      flag = false;
    }else if(authType === ""){
      enqueueSnackbar("Please select Auth Type", { variant: "error" });
      flag = false;
    }
     else if (!(letterNumber.test(config) && config.length < 50)) {
      enqueueSnackbar(
        "Invalid configuration name (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)",
        { variant: "error" }
      );
      flag = false;
    } else if ((configDesc || '').trim() === "") {
      enqueueSnackbar(
        "Please enter Configuration Description.",
        { variant: "error" }
      );
      flag = false;
    }
    tags.forEach((tag: any) => {
      if(tag.fieldType === 'Text' && tag.dataType==='number') {
        if(isNaN(tag.value)) {
          enqueueSnackbar(
            `Invalid value for ${tag.fieldName} (Data type is number)`,
            { variant: "error" }
          );
          flag = false;
        }
        if((tag.value || '').trim() === '') {
          enqueueSnackbar(
            `Tag default value cannot be empty for 'number' tags. Please enter zero for ${tag.fieldName}`,
            { variant: "error" }
          );
          flag = false;
        }
      } else if (
        tag.fieldType === "Tabular" &&
        !(letterNumber1.test(tag.value) && tag.value.length < 50)
      ) {
        enqueueSnackbar(
          `Invalid value for ${tag.fieldName} (only 0-9,A-Z,a-z,|,_,-, allowed upto 50 characters for tabular fields)`,
          { variant: "error" }
        );
        flag = false;
      }
    });
    properties.forEach((property: any) => {
      if (!(letterNumber.test(property.value) && property.value.length < 50)) {
        enqueueSnackbar(
          `Invalid value for ${property.fieldName} (only 0-9,A-Z,a-z,_,-,. allowed upto 50 characters)`,
          { variant: "error" }
        );
        flag = false;
      }
    });
    dropdowns.forEach((dropdown: any) => { });
    if (!letterNumber.test(configDesc)) {
      enqueueSnackbar(
        "Invalid configuration description (only 0-9,A-Z,a-z,_,-,. allowed).",
        { variant: "error" }
      );
      flag = false;
    }
    if (flag) {
      saveConfigAction();
    }
  };

  const saveConfigAction = () => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);

    interface sessionObj {
      pm_s_id: string;
    }
    interface tokenObj {
      pm_s_token: string;
    }
    let pmSessionId: sessionObj = {
      pm_s_id: "",
    };
    let pmSessionToken: tokenObj = {
      pm_s_token: "",
    };
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));
    const tagg = {} as any;
    tags.forEach((tag: any) => {
      if (tag.fieldType === "Dropdown") {
        tagg[`${tag.fieldName}`] = {
          value: "",
          type: tag.fieldType,
          data_type: tag.dataType,
          is_visible_as_column: tag.showAsColumn,
          options: tag.value,
        };
      } else {
        tagg[`${tag.fieldName}`] = {
          value: tag.value,
          type: tag.fieldType,
          data_type: tag.dataType,
          is_visible_as_column: tag.showAsColumn,
        };
      }
    });

    const propp = {} as any;
    properties.forEach((property: any) => {
      propp[`${property.fieldName}`] = property.value;
    });

    const tag_dropdowns = {} as any;
    dropdowns.forEach((dropdown: any) => {
      //id, tagName, fieldName, value
      if (tag_dropdowns[`${dropdown.tagName}`]) {
        tag_dropdowns[`${dropdown.tagName}`][`${dropdown.fieldName}`] =
          dropdown.value;
      } else {
        tag_dropdowns[`${dropdown.tagName}`] = {};
        tag_dropdowns[`${dropdown.tagName}`][`${dropdown.fieldName}`] =
          dropdown.value;
      }
    });

    const CONFIG_API = `${API["GETALLAPPLS"]}/${app}/device-configs`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken.access_token}`,
        "Ocp-Apim-Subscription-Key": `${API_KEY}`,
        "Ocp-Apim-Trace": `true`,
      },
      body: JSON.stringify({
        pm_s_id: pmSessionId,
        pm_s_token: pmSessionToken,
        device_config: {
          config_name: config,
          config_desc: configDesc,
          device_type: deviceType,
          auth_type: authType,
          edge_type: edgeType,
          add_to_iot_hub: "y",
          tags: tagg,
          properties: propp,
          dropdown: tag_dropdowns,
        },
      }),
    };
    setbackDrop(true);
    fetch(CONFIG_API, requestOptions)
      .then(function (response: any) {
        response.json().then((response: any) => {
        if (
          (response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))
        ) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest()
              .then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                saveConfigAction();
              })
              .catch(function (error) {
                user.triggerLogout();
                console.log("error", error);
              });
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if (
          (response && response.status === 200) ||
          (response && response.status === 201)
        ) {
          enqueueSnackbar("Device Configuration Created", {
            variant: "success",
          });
          setbackDrop(false);
          setTimeout(function () {
            props.returnHome();
            setConfig("");
            setConfigDesc("");
            setTags([]);
            setDropdowns([]);
            setProperties([]);
          }, 500);
        } else {
          const errorMessage = typeof response.data === 'string' ? response.data : "Device Configuration Creation Failed";
          enqueueSnackbar(errorMessage, {
            variant: "error",
          });
          setbackDrop(false);
        }
      })
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    interface sessionObj {
      pm_s_id: string;
    }
    interface tokenObj {
      pm_s_token: string;
    }
    let pmSessionId: sessionObj = {
      pm_s_id: "",
    };
    let pmSessionToken: tokenObj = {
      pm_s_token: "",
    };
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));
    const GETALLAPPS_API =
      API["GETALLAPPLS"] +
      `?pm_s_token=${pmSessionToken}&pm_s_id=${pmSessionId}`;
    fetch(GETALLAPPS_API, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken.access_token}`,
        "Ocp-Apim-Subscription-Key": `${API_KEY}`,
        "Ocp-Apim-Trace": `true`,
      },
    })
      .then((resp) => resp.json())
      .then(function (response) {
        if (
          (response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))
        ) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest()
              .then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                setReload(!reload);
              })
              .catch(function (error) {
                user.triggerLogout();
                console.log("error", error);
              });
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        if (response && response.status === 200) {
          setApplication(response.applications);
        }
      })
      .catch(function (error) {
        console.log(error);
      });


      const CONFIG_INPUT_API = `${API["DEVICE_CONFIG_INPUT"]}?pm_s_token=${pmSessionToken}&pm_s_id=${pmSessionId}`;
      fetch(CONFIG_INPUT_API, {
        headers: {
          Authorization: `Bearer ${accessToken.access_token}`,
          "Ocp-Apim-Subscription-Key": `${API_KEY}`,
          "Ocp-Apim-Trace": `true`,
        },
      })
        .then((resp) => resp.json())
        .then(function (response) {
          if (
            (response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))
          ) {
            try {
              const tokenKey = "sso_token";
              callXhrRequest()
                .then(function (data) {
                  sessionStorage.setItem(tokenKey, data);
                  setReload(!reload);
                })
                .catch(function (error) {
                  user.triggerLogout();
                  console.log("error", error);
                });
            } catch (error) {
              console.log(error);
            }
            return;
          }
          else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
            try {
              user.triggerLogout();
            } catch (error) {
              console.log(error);
            }
            return;
          }
          if (response && response.status === 200) {
            setConfigInputData(response.devices_config_input[0]?.applications);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
  }, [API_KEY, reload]);

  const onCancel = () => {
    setOpen(false);
    setOpenDropdown(false);
    setTagDrops({ id: "", tagName: "", fieldName: "", value: "" });
    props.returnHome();
    setApp("");
    setConfig("");
    setConfigDesc("");
    setTags([]);
    setDropdowns([]);
    setProperties([]);
    setAddFieldInput([
      {
        id: "",
        fieldName: "",
        fieldType: "",
        value: "",
        category: "",
        showAsColumn: true,
        dataType:"",
        options: [],
      },
    ]);
  }
  return (
    <div className={classes.root}>
      <div
        className={classes.gridRoot}
        style={{ marginRight: "1.5em", marginBottom: "1.5em", width: "100%" }}
      >
        <Grid
          container
          direction="row"
          xs={12}
          sm={6}
          style={{ marginTop: "1em", marginBottom: "1em" }}
          alignItems="center"
        >
          <BreadcumbComponent route='addDeviceConfig' func={onCancel}/>
        </Grid>
      </div>
      <Typography className={classes.subtitle}>
        All fields marked * are mandatory
      </Typography>

      <form className={classes.form} noValidate method="post">
        <Grid container>
          <Grid item xs={6} className={classes.spaceRight}>
            <div className={classes.fieldWrapper}>
              <InputLabel
                classes={{ root: classes.labels }}
                htmlFor="selectApplication"
              >
                Application *
              </InputLabel>
              <TextField
                className={classes.textfield}
                fullWidth
                select
                variant="outlined"
                classes={{ root: classes.fieldRoot }}
                inputProps={{ className: classes.field }}
                value={app}
                id="selectApplication"
                SelectProps={{
                  classes: {
                    iconOutlined: classes.icon,
                  },
                }}
                onChange={handleChangeApp}
              >
                {uApplication.map((option: any) => (
                  <option
                    className={classes.options}
                    key={option.application_id}
                    value={option.application_id}
                  >
                    {option.application_name}
                  </option>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={6} className={classes.spaceLeft}>
            <CssTextField
              fullWidth
              name="config"
              label="Configuration Name *"
              type="Text"
              id="config"
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
              onChange={(event) => handleChangeConfig(event)}
              value={config}
            />
          </Grid>
          <Grid item xs={6} className={classes.spaceRight}>
            <div className={classes.fieldWrapper}>
              <InputLabel
                classes={{ root: classes.labels }}
                htmlFor="Device Type"
              >
                Device Type *
              </InputLabel>
              <TextField
                className={classes.textfield}
                fullWidth
                select
                variant="outlined"
                classes={{ root: classes.fieldRoot }}
                inputProps={{ className: classes.field }}
                value={deviceType}
                id={deviceType}
                SelectProps={{
                  classes: {
                    iconOutlined: classes.icon,
                  },
                }}
                onChange={(event) => setDeviceType(event.target.value)}
              >
                {deviceOptions.map((option: any) => (
                  <option
                    className={classes.options}
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={6} className={classes.spaceLeft}>
            <div className={classes.fieldWrapper}>
              <InputLabel
                classes={{ root: classes.labels }}
                htmlFor="Edge Type"
              >
                Edge Type *
              </InputLabel>
              <TextField
                className={classes.textfield}
                fullWidth
                select
                variant="outlined"
                classes={{ root: classes.fieldRoot }}
                inputProps={{ className: classes.field }}
                value={edgeType}
                id={edgeType}
                defaultValue="Non-Edge"
                SelectProps={{
                  classes: {
                    iconOutlined: classes.icon,
                  },
                }}
                onChange={(event) => setEdgeType(event.target.value)}
              >
                {edgeOptions.map((option: any) => (
                  <option
                    className={classes.options}
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={6} className={classes.spaceRight}>
            <div className={classes.fieldWrapper}>
              <InputLabel
                classes={{ root: classes.labels }}
                htmlFor="Auth Type"
              >
                Auth Type *
              </InputLabel>
              <TextField
                className={classes.textfield}
                fullWidth
                select
                variant="outlined"
                classes={{ root: classes.fieldRoot }}
                inputProps={{ className: classes.field }}
                value={authType}
                id={authType}
                SelectProps={{
                  classes: {
                    iconOutlined: classes.icon,
                  },
                }}
                onChange={(event) => setAuthType(event.target.value)}
              >
                {authOptions.map((option: any) => (
                  <option
                    className={classes.options}
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.spaceRight}>
            <CssTextField
              fullWidth
              name="configDesc"
              label="Configuration Description *"
              type="Text"
              id="configDesc"
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
              onChange={(event) => handleChangeConfigDesc(event)}
              value={configDesc}
            />
          </Grid>
          <Grid item xs={12}>
            <List
              dense={true}
              classes={{ root: classes.noSpace }}
              className={classes.spaceTop}
            >
              <ListItem classes={{ root: classes.noSpace }}>
                <ListItemText
                  classes={{ root: classes.noSpace }}
                  primary="Tags"
                  primaryTypographyProps={{
                    style: {
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: "16px",
                    },
                  }}
                  secondaryTypographyProps={{
                    style: {
                      color: "#FCFCFC",
                      fontSize: "12px",
                      marginTop: "10px",
                    },
                  }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid
            container
            xs={12}
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.topGrid}
          >
            {tags.map((tag: any, index: any) => {
              if (tag.fieldType === "Text" || tag.fieldType === "Tabular") {
                return (
                  <Grid
                    item
                    xs={6}
                    style={{ marginTop: "16px" }}
                    className={
                      index % 2 === 1 ? classes.spaceLeft : classes.spaceRight
                    }
                  >
                    <InputLabel
                      style={{ marginTop: "1em" }}
                      classes={{ root: classes.labels }}
                      htmlFor={tag.fieldName}
                    >
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                      >
                        {tag.fieldType === "Tabular"
                          ? `${tag.fieldName} ( Enter column names separated by | )`
                          : `${tag.fieldName} ( Enter default vaule for tag or leave blank )`}
                        <div>
                          <DeleteIcon
                            onClick={() => {
                              setTags(
                                [...tags].filter((item: any) => item !== tag)
                              );
                            }}
                            style={{ marginLeft: "1em" }}
                            fontSize="small"
                          ></DeleteIcon>
                        </div>
                      </Grid>
                    </InputLabel>
                    <CssTextFieldVariable
                      fullWidth
                      name={tag.fieldName}
                      type={tag.fieldType}
                      id={tag.fieldName}
                      style={{ marginTop: "16px" }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labels,
                      }}
                      onChange={(event) => handleChangeTags(index, event)}
                      value={tag.value}
                    />
                  </Grid>
                );
              } else if (tag.fieldType === "Dropdown") {
                return (
                  <Grid
                    item
                    xs={6}
                    style={{ marginTop: "16px" }}
                    className={
                      index % 2 === 1 ? classes.spaceLeft : classes.spaceRight
                    }
                  >
                    <InputLabel
                      style={{ marginTop: "1em" }}
                      classes={{ root: classes.labels }}
                      htmlFor={tag.fieldName}
                    >
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                      >
                        {`${tag.fieldName} ( Enter applicable values separated by | )`}
                        <div>
                          <DeleteIcon
                            onClick={() => {
                              setTags(
                                [...tags].filter((item: any) => item !== tag)
                              );
                            }}
                            style={{ marginLeft: "1em" }}
                            fontSize="small"
                          ></DeleteIcon>
                        </div>
                      </Grid>
                    </InputLabel>
                    <CssTextFieldVariable
                      fullWidth
                      name={tag.fieldName}
                      type={tag.fieldType}
                      id={tag.fieldName}
                      style={{ marginTop: "16px" }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labels,
                      }}
                      onChange={(event) => handleChangeTags(index, event)}
                      value={tag.value}
                    />
                  </Grid>
                );
              } else return null;
            })}
          </Grid>

          <Grid item xs={12}>
            <List
              dense={true}
              classes={{ root: classes.noSpace }}
              className={classes.spaceTop}
            >
              <ListItem classes={{ root: classes.noSpace }}>
                <ListItemText
                  classes={{ root: classes.noSpace }}
                  primary="Dropdowns for Tabular Tags"
                  primaryTypographyProps={{
                    style: {
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: "16px",
                    },
                  }}
                  secondaryTypographyProps={{
                    style: {
                      color: "#FCFCFC",
                      fontSize: "12px",
                      marginTop: "10px",
                    },
                  }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid
            container
            xs={12}
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.topGrid}
          >
            {dropdowns.map((tag: any, index: any) => {
              return (
                <Grid
                  item
                  xs={6}
                  style={{ marginTop: "16px" }}
                  className={
                    index % 2 === 1 ? classes.spaceLeft : classes.spaceRight
                  }
                >
                  <InputLabel
                    style={{ marginTop: "1em" }}
                    classes={{ root: classes.labels }}
                    htmlFor={tag.fieldName}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      {tag.tagName +
                        " : " +
                        tag.fieldName +
                        " ( Enter applicable values separated by | )"}
                      <div>
                        <DeleteIcon
                          onClick={() => {
                            setDropdowns(
                              [...dropdowns].filter((item: any) => item !== tag)
                            );
                          }}
                          style={{ marginLeft: "1em" }}
                          fontSize="small"
                        ></DeleteIcon>
                      </div>
                    </Grid>
                  </InputLabel>
                  <CssTextFieldVariable
                    fullWidth
                    name={tag.fieldName}
                    id={tag.fieldName}
                    style={{ marginTop: "16px", color: "#FFFFFF" }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.labels,
                    }}
                    onChange={(event) => handleChangeDropdowns(index, event)}
                    value={tag.value}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Button onClick={() => setOpenDropdown(true)}>
            <Typography className={classes.addNewFieldText}>
              Add new dropdown specifications
            </Typography>
          </Button>

          <Grid item xs={12}>
            <List
              dense={true}
              classes={{ root: classes.noSpace }}
              className={classes.spaceTop}
            >
              <ListItem classes={{ root: classes.noSpace }}>
                <ListItemText
                  classes={{ root: classes.noSpace }}
                  primary="Properties"
                  primaryTypographyProps={{
                    style: {
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: "16px",
                    },
                  }}
                  secondaryTypographyProps={{
                    style: {
                      color: "#FCFCFC",
                      fontSize: "12px",
                      marginTop: "10px",
                    },
                  }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid
            container
            xs={12}
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.topGrid}
          >
            {properties.map((property: any, index: any) => {
              if (property.fieldType === "Text") {
                return (
                  <Grid
                    item
                    xs={6}
                    style={{ marginTop: "16px" }}
                    className={
                      index % 2 === 1 ? classes.spaceLeft : classes.spaceRight
                    }
                  >
                    <InputLabel
                      style={{ marginTop: "1em" }}
                      classes={{ root: classes.labels }}
                      htmlFor={property.fieldName}
                    >
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                      >
                        {property.fieldName}
                        <div>
                          <DeleteIcon
                            onClick={() => {
                              setProperties(
                                [...properties].filter(
                                  (item: any) => item !== property
                                )
                              );
                            }}
                            style={{ marginLeft: "1em" }}
                            fontSize="small"
                          ></DeleteIcon>
                        </div>
                      </Grid>
                    </InputLabel>
                    <CssTextFieldVariable
                      fullWidth
                      name={property.fieldName}
                      type={property.fieldType}
                      id={property.fieldName}
                      style={{ marginTop: "16px", color: "#FFFFFF" }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labels,
                      }}
                      onChange={(event) => handleChangeProperties(index, event)}
                      value={property.value}
                    />
                  </Grid>
                );
              } else if (property.fieldType === "Dropdown") {
                return (
                  <Grid
                    item
                    xs={6}
                    className={
                      index % 2 === 1 ? classes.spaceLeft : classes.spaceRight
                    }
                  >
                    <div className={classes.fieldWrapper}>
                      <InputLabel
                        classes={{ root: classes.labels }}
                        htmlFor={property.fieldName}
                      >
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                        >
                          {property.fieldName}
                          <div>
                            <DeleteIcon
                              onClick={() => {
                                setProperties(
                                  [...properties].filter(
                                    (item: any) => item !== property
                                  )
                                );
                              }}
                              style={{ marginLeft: "1em" }}
                              fontSize="small"
                            ></DeleteIcon>
                          </div>
                        </Grid>
                      </InputLabel>
                      <TextField
                        className={classes.textfield1}
                        fullWidth
                        select
                        variant="outlined"
                        classes={{ root: classes.fieldRoot }}
                        inputProps={{ className: classes.field }}
                        value={property.value}
                        id={property.fieldName}
                        SelectProps={{
                          classes: {
                            iconOutlined: classes.icon,
                          },
                        }}
                        onChange={(event) =>
                          handleChangeProperties(index, event)
                        }
                      >
                        {property.options.map((option: any) => (
                          <option
                            className={classes.options}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </div>
                  </Grid>
                );
              } else return null;
            })}
          </Grid>
        </Grid>
        <Button onClick={() => setOpen(true)}>
          <Typography className={classes.addNewFieldText}>
            Add New Tag/Property
          </Typography>
        </Button>

        <Grid container direction="column">
          <FormControlLabel
            classes={{
              root: classes.checkboxRoot,
              label: classes.checkboxLabel,
            }}
            control={
              <Checkbox
                checked={checked}
                onChange={handleChangeChecked}
                name="checked"
                color="primary"
                className={classes.bgColorBlue}
              />
            }
            label="Add to IoT Hub"
          />
        </Grid>
        <Dialog
          fullWidth
          classes={{ paper: classes.dialogRoot }}
          onClose={() => setOpen(false)}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setOpen(false)}
          >
            Add New Field
          </DialogTitle>
          <DialogContent className={classes.addNewFieldContentHolder}>
            <form>
              {addFieldInput.map((inputField, index) => (
                <div>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justify="space-around"
                  >
                    <Grid item className={`${classes.spaceLeft} ${classes.compactInput}`}>
                      <CssTextField
                        fullWidth
                        name="fieldName"
                        label="Field Name *"
                        type="fieldName"
                        id="fieldName"
                        className={classes.fieldWrapper}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labels,
                        }}
                        onChange={(event) =>
                          handleChangeAddNewFields(index, event)
                        }
                        value={inputField.fieldName}
                      />
                    </Grid>
                    <Grid item className={`${classes.spaceLeft} ${classes.compactInput}`}>
                      <div className={classes.fieldWrapper}>
                        <InputLabel
                          classes={{ root: classes.labels }}
                          htmlFor="fieldType"
                        >
                          Field Type *
                        </InputLabel>
                        <TextField
                          className={classes.textfield}
                          fullWidth
                          select
                          variant="outlined"
                          name="fieldType"
                          classes={{ root: classes.fieldRoot }}
                          inputProps={{ className: classes.field }}
                          value={inputField.fieldType}
                          id="fieldType"
                          SelectProps={{
                            classes: {
                              iconOutlined: classes.icon,
                            },
                          }}
                          onChange={(event) =>
                            handleChangeAddNewFields(index, event)
                          }
                        >
                          {newFieldOptions.map((option: any) => (
                            <option
                              className={classes.options}
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </div>
                    </Grid>
                    <Grid item className={`${classes.spaceLeft} ${classes.compactInput}`}>
                      <div className={classes.fieldWrapper}>
                        <InputLabel
                          classes={{ root: classes.labels }}
                          htmlFor="fieldType"
                        >
                          Data Type *
                        </InputLabel>
                        <TextField
                          className={classes.textfield}
                          fullWidth
                          select
                          variant="outlined"
                          name="dataType"
                          classes={{ root: classes.fieldRoot }}
                          inputProps={{ className: classes.field }}
                          value={inputField.dataType}
                          id="dataType"
                          SelectProps={{
                            classes: {
                              iconOutlined: classes.icon,
                            },
                          }}
                          onChange={(event) =>
                            handleChangeAddNewFields(index, event)
                          }
                        >
                          {newFieldOptionsDataType.map((option: any) => (
                            <option
                              className={classes.options}
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </div>
                    </Grid>
                    <Grid item className={`${classes.spaceLeft} ${classes.compactInput}`}>
                      <div className={classes.fieldWrapper}>
                        <InputLabel
                          classes={{ root: classes.labels }}
                          htmlFor="fieldType"
                        >
                          Category *
                        </InputLabel>
                        <TextField
                          className={classes.textfield}
                          fullWidth
                          select
                          variant="outlined"
                          name="category"
                          classes={{ root: classes.fieldRoot }}
                          inputProps={{ className: classes.field }}
                          value={inputField.category}
                          id="category"
                          SelectProps={{
                            classes: {
                              iconOutlined: classes.icon,
                            },
                          }}
                          onChange={(event) =>
                            handleChangeAddNewFields(index, event)
                          }
                        >
                          {newFieldOptionsCategory.map((option: any) => (
                            <option
                              className={classes.options}
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </div>
                    </Grid>
                    <Grid item className={`${classes.spaceLeft} ${classes.compactInput}`}  style={{padding:'0px'}}>
                      <FormControlLabel
                        classes={{
                          root: classes.checkboxRoot,
                          label: classes.checkboxLabel,
                        }}
                        control={
                          <Checkbox
                            checked={inputField.showAsColumn}
                            onChange={(event) => {
                              const values = [...addFieldInput];
                              values[index][event.target.name] = event.target.checked;
                              values[index]["id"] = Date.now().toString();
                              setAddFieldInput(values);
                            }}
                            name="showAsColumn"
                            color="primary"
                            className={classes.bgColorBlue}
                           
                          />
                        }
                        labelPlacement="top"
                        label="Column View"
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Button style={{marginTop:'35px'}} onClick={() => handleDeleteNewFields(index)}>
                        <DeleteIcon style={{ color: "#FFFFFF" }}></DeleteIcon>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ))}
              <Button onClick={() => handleAddNewFields()}>
                <Typography className={classes.buttonText}>
                  Add New Field
                </Typography>
              </Button>
            </form>
          </DialogContent>
          <ThemeProvider theme={theme1}>
            <Divider
              variant="middle"
              classes={{ middle: classes.dividerColor }}
            />
          </ThemeProvider>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
                setAddFieldInput([
                  {
                    id: "",
                    fieldName: "",
                    fieldType: "",
                    value: "",
                    category: "",
                    dataType:"",
                    showAsColumn: true,
                    options: [],
                  },
                ]);
              }}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmitNewFields}
              variant="contained"
              className="dcBtEdit"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          classes={{ paper: classes.dialogRoot }}
          onClose={() => setOpenDropdown(false)}
          aria-labelledby="customized-dialog-title"
          open={openDropdown}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setOpenDropdown(false)}
          >
            Add New Dropdown Specifications
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <form>
              <div>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justify="space-around"
                >
                  <Grid item className={`${classes.spaceLeft} ${classes.compactInput}`}>
                    <CssTextField
                      fullWidth
                      name="tagName"
                      label="Tag Name *"
                      type="tagName"
                      id="tagName"
                      className={classes.fieldWrapper}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labels,
                      }}
                      onChange={(event) => handleChangeTagDrops(event)}
                      value={tagDrops.tagName}
                    />
                  </Grid>
                  <Grid item className={classes.spaceLeft}>
                    <CssTextField
                      fullWidth
                      name="fieldName"
                      label="Dropdown Field Name *"
                      type="fieldName"
                      id="fieldName"
                      className={classes.fieldWrapper}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labels,
                      }}
                      onChange={(event) => handleChangeTagDrops(event)}
                      value={tagDrops.fieldName}
                    />
                  </Grid>
                </Grid>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDropdown(false);
                setTagDrops({ id: "", tagName: "", fieldName: "", value: "" });
              }}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmitNewDropdownFields}
              variant="contained"
              className="dcBtEdit"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <div className={`${classes.space2Top} ${classes.bottomSection}`}>
          <Button
            variant="outlined"
            className={classes.btnCancel}
            onClick={() => {
              props.returnHome();
              setApp("");
              setConfig("");
              setConfigDesc("");
              setTags([]);
              setDropdowns([]);
              setProperties([]);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.btnAddNDevice}
            onClick={() => validateField()}
          >
            Save Configuration
          </Button>
        </div>
      </form>
      <Backdrop
        className={classes.backdrop}
        open={backDrop}
        onClick={backDropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default function AddNewConfig(props: any) {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <AddNewConfigWrapper returnHome={props.returnHome} />
    </SnackbarProvider>
  );
}
