import React, { useEffect, useState } from "react";
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  WithStyles,
  FormControlLabel,
  ListItemText,
  ListItem,
  List,
  Backdrop,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TableBody,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { useSnackbar } from "notistack";
import EditIcon from "@material-ui/icons/Edit";
import Checkbox from "@material-ui/core/Checkbox";
import { API } from "../../../../api/property";
import callXhrRequest from "../../../../utils/xhrRequestHandler";
import {
  arrayOfErrorsRefresh,
  arrayOfErrorsLogout,
} from "../../../../utils/helper";
import { useStore } from "mobx-store-provider";
import {
  DEFAULT_INSTALL_STATUS_VALUE,
  EXPIRE_LIMITED_DAYES,
} from "../../../../utils/constant";
import validateTags from "../utils/validateTags";
import CustomTextField from "../../../UiComponents/TextField/TextField";
import { queueJob } from "../../../UiComponents/NotificationManager";
import { JOB_TYPE } from "../../../UiComponents/NotificationManager/types";

const border_style = "1px solid #464659";
const border_style2 = "1px solid rgba(235,235,245,0.35)";
const border_box = "border-box";
const alt_label_cover = "red !important";
const font = "Avenir Next";

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#2B2B36",
      },
    },
  })
)(TableRow);

const StyledTableCell = withStyles((theme) =>
  createStyles({
    root: {
      borderBottom: "none",
    },
    head: {
      backgroundColor: "#464659",
      color: "#FFFFFF",
      borderBottom: "none",
      padding: "8px 16px",
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
      color: "#FFFFFF",
      backgroundColor: "none",
      padding: "12px",
    },
  })
)(TableCell);

const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: border_style,
      borderRadius: "2px",
      marginTop: "16px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: "30px",
    },
    noSpace: {
      padding: "0px",
      margin: "0px",
    },
    spaceTop: {
      marginTop: "35px",
    },
    space2Top: {
      marginTop: "20px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    spaceLeft: {
      paddingLeft: "10px",
    },
    menuButtonRoot: {
      padding: "0px",
    },
    formTitle: {
      color: "#FCFCFC",

      fontWeight: "bold",
      fontSize: "21px",
      lineHeight: "25px",
    },
    subtitle: {
      color: "#373746",
      fontSize: "12px",
      lineHeight: "14px",
      marginTop: "23px",
    },
    form: {
      width: "100%",
    },
    fieldWrapper: {
      marginTop: "20px",
      color: "#FCFCFC",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
    labels1: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
      marginTop: "1em",
      marginBottom: "1em",
    },
    gridRoot: {
      flexGrow: 1,
      padding: "0.5em 24px",
      backgroundColor: "#2B2B36",
    },
    noSpaceNoMin: {
      margin: "0px !important",
      padding: "0px !important",
      minHeight: "auto !important",
    },
    typoRoot: {
      paddingLeft: "24px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "14px",
      lineHeight: "16px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: border_style,
      borderRadius: "2px",
      boxSizing: border_box,
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
    },
    fieldRoot: {
      marginTop: "16px",
    },
    selectRoot: {
      padding: "24px",
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "20px",
    },
    btnCancel: {
      borderRadius: "2px",
      border: border_style2,
      color: "#0089FF",
      background: "none",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    btnAddNDevice: {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#0089ff",
      },
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    vp: {
      backgroundColor: "#2B2B36",
      border: border_style,
      borderRadius: "2px",
      color: "#FCFCFC",
      "&:hover": {
        backgroundColor: "#2B2B36",
      },
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "none",
      padding: "12px 0px",
      marginTop: "34px",
      width: "100%",
    },
    buttonText: {
      color: "#0089FF",
      margin: "1em",
      textTransform: "none",
      fontSize: "14px",
    },
    addNewFieldText: {
      color: "#0089FF",
      marginTop: "1em",
      marginBottom: "1em",
      textTransform: "none",
      fontSize: "16px",
      lineHeight: "19px",
    },
    helperfield: {
      color: "#FCFCFC",
      fontSize: "12px",
      lineHeight: "14px",
      padding: "10px",
    },
    textfield1: {
      color: "#FCFCFC",
      border: `1px solid #464659`,
      boxSizing: border_box,
      borderRadius: "2px",
      backgroundColor: "#2B2B36",
    },
    dropButton: {
      textTransform: "none",
      fontSize: "16px",
      margin: "0px",
      border: "1px solid #6E6E79",
      boxSizing: border_box,
      borderRadius: "4px",
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      minWidth: "800px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style2,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRootSmall: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style2,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },

    items: {
      color: "#FCFCFC",
      marginLeft: "2em",
      marginRight: "2em",
      marginTop: "1em",
      marginBottom: "1em",
    },
    dividerColor: {
      background: "#464659",
    },
    space2TopnBottom: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    stepperRoot: {
      width: "50%",
      margin: "0 auto",
    },
    stepname: {
      color: "#FFF",
      "& span": {
        display: "flex",
        flexDirection: "column",
      },
    },
    stepactive: {
      color: "#FFF",
    },
    steplabel: {
      color: "#FFF",
      "& span": {
        color: "darkgray !important",
      },
      "& .active": {
        color: "green !important",
      },
    },
    step: {
      //color: '#ddd !important',
      "&$completed": {
        color: "#0089ff",
      },
      "&$active": {
        color: "cornflowerblue",
      },
      "&$disabled": {
        color: "red",
      },
    },
    alternativeLabel: {
      color: "yellow !important",
      "&$active": {
        color: alt_label_cover,
      },
    },
    active: {
      color: "cornflowerblue !important",
    },
    completed: {
      color: "#0089ff !important",
    },
    disabled: {
      color: "red",
    },
    labelContainer: {
      color: alt_label_cover,
      "&$alternativeLabel": {
        marginTop: 0,
        color: "orange !important",
      },
      "&$active": {
        color: alt_label_cover,
      },
      "&$completed": {
        color: "green !important",
      },
    },
    hidden: {
      display: "none",
    },
    appContainer: {
      padding: "1rem",
      background: "#2b2b36",
      marginTop: "1rem",
    },
    colorWhite: {
      color: "#FFFFFF",
    },
    appTitle: {
      width: "100%",
    },
    tableWrapper: {
      marginTop: "15px",
      boxShadow: "none",
      overflow: "hidden",
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    textField: {
      width: "16ch",
      color: "#fff",
      padding: "3px 10px",
      border: "1px solid gray",
    },
    margin: {
      margin: theme.spacing(1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    bgColorBlue: {
      color: "#1976d2 !important",
    },
    checkboxLabel: {
      fontFamily: font,
      fontSize: "16px",
      lineHeight: "28px",
      color: "#FFFFFF",
    },
    checkboxRoot: {
      marginTop: "2em",
      marginBottom: "1em",
      maxWidth: "18em",
    },
    gridVar: {
      fontFamily: font,
      fontSize: "16px",
      fontWeight: "bold",
      lineHeight: "19px",
      color: "#FFFFFF",
      marginTop: "10px",
      marginLeft: "20px",
      marginRight: "20px",
      marginBottom: "10px",
    },
    gridVal: {
      fontFamily: font,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#FFFFFF",
      marginTop: "10px",
      marginLeft: "20px",
      marginBottom: "10px",
      marginRight: "20px",
    },
    topGrid: {
      height: "91px",
      marginLeft: "5px",
      marginRight: "32px",
      marginTop: "1em",
      marginBottom: "1em",
      background: "#2B2B36",
      borderRadius: "4px",
    },
    topGrid1: {
      marginTop: "1em",
      paddingLeft: "30px",
      paddingRight: "30px",
      paddingBottom: "30px",
      background: "#2B2B36",
      borderRadius: "4px",
    },
  })
);

const CssTextFieldVariable = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: border_style,
      borderRadius: "2px",
    },
    "& .MuiInput-multiline": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: border_style,
      borderRadius: "2px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const letterNumber = /^[0-9a-zA-Z_-\s]*$/;
const letterNumberID = /^[0-9a-zA-Z_-]*$/;
const tthex = /^[0-9a-fA-F]{32}$/;
const sthex = /^[0-9a-fA-F]{16}$/;

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
  });
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 15px",
    padding: 0,
    color: "#FFFFFF",
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "20px 15px",
    justifyContent: "space-between",
    padding: 0,
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

const AddNewDeviceWrapper: any = (props: any) => {
  const { user } = useStore();

  const classes = useStyles();
  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;

  interface sessionObj {
    pm_s_id: string;
  }
  interface tokenObj {
    pm_s_token: string;
  }
  let pmSessionId: sessionObj = {
    pm_s_id: "",
  };
  let pmSessionToken: tokenObj = {
    pm_s_token: "",
  };
  pmSessionId =
    sessionStorage.getItem("pm_s_id") ||
    JSON.parse(JSON.stringify(pmSessionId));
  pmSessionToken =
    sessionStorage.getItem("pm_s_token") ||
    JSON.parse(JSON.stringify(pmSessionToken));
  const tokenKey = "sso_token";
  const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);

  const newFieldOptions = [
    {
      value: "Text",
      label: "Text",
    },
  ];

  const [dropdown, setDropdown] = React.useState({
    tag: {
      column1: "val1|val2|val3",
      column2: "op1|op2|op3|op4",
    },
  });

  const [backDrop, setbackDrop] = React.useState(false);

  const [configs, setConfigs] = React.useState([] as any);

  //conn config variables
  const [connConfigs, setConnConfigs] = React.useState([] as any);
  const [connConfig, setConnConfig] = React.useState("");

  //loriot variables
  const backDropClose = () => {
    setbackDrop(false);
  };
  const [ltitle, setLtitle] = React.useState("");
  const handleChangeLtitle = (event: any) => {
    setLtitle(event.target.value);
  };
  const [ldesc, setLdesc] = React.useState("");
  const handleChangeLdesc = (event: any) => {
    setLdesc(event.target.value);
  };
  const [devclass, setDevclass] = React.useState("");
  const handleChangeDevclass = (event: any) => {
    setDevclass(event.target.value);
  };
  const [appkey, setAppkey] = React.useState("");
  const handleChangeAppkey = (event: any) => {
    setAppkey(event.target.value);
  };
  const [deveui, setDeveui] = React.useState("");
  const handleChangeDeveui = (event: any) => {
    setDeveui(event.target.value);
  };
  const [appeui, setAppeui] = React.useState("");
  const handleChangeAppeui = (event: any) => {
    setAppeui(event.target.value);
  };

  const [selectedConnConfig, setSelectedConnConfig] = React.useState({} as any);
  const [reload, setReload] = React.useState(false);

  const autoPopulateLoriotFields = () => {
    const deviceId = tags[0].value;
    setLtitle(deviceId);
    setLdesc(deviceId);
    setDeveui(deviceId);
    setDevclass("A");
  };

  const handleChangeConnConfig = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConnConfig(event.target.value);
    connConfigs.forEach((config: any) => {
      if (config.conn_config_id === event.target.value) {
        setSelectedConnConfig(config);
      }
    });
    autoPopulateLoriotFields();
  };

  const [checked, setChecked] = React.useState(false as any);
  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(!checked);
  };

  const [tags1, setTags1] = React.useState([] as any);
  const [properties, setProperties] = React.useState([] as any);

  const deviceID = {
    fieldName: "Device Id *",
    fieldType: "Text",
    value: "",
    options: [],
  };
  const deviceName = {
    fieldName: "Device  Name *",
    fieldType: "Text",
    value: "",
    options: [],
  };

  const serial = {
    fieldName: "Serial Number *",
    fieldType: "Text",
    value: "",
    options: [],
  };
  const [gateway, setGateway] = React.useState({
    fieldName: "IoT Hub *",
    fieldType: "Dropdown",
    value: "",
    options: [],
  } as any);
  const [app_level, setApp_level] = React.useState({
    fieldName: "Application Level *",
    fieldType: "Dropdown",
    value: "",
    options: [],
  } as any);

  const [installationStatus, setInstallationStatus] = React.useState({
    fieldName: "Installation Status",
    fieldType: "Dropdown",
    value: "",
    options: [],
  });

  const [application, setApplication] = React.useState([] as any);
  const [config, setConfig] = React.useState("" as any);

  const [app, setApp] = React.useState("" as any);
  const handleChangeApp = (event: React.ChangeEvent<HTMLInputElement>) => {
    application.forEach((app: any) => {
      if (app.application_id === event.target.value) {
        const levels = [] as any;
        if (app.application_level) {
          app.application_level.forEach((level: any) => {
            levels.push({ label: level.level_name, value: level.level_id });
          });
        }
        const iot_hubs = [] as any;
        if (app.iot_hubs) {
          app.iot_hubs.forEach((hub: any) => {
            iot_hubs.push({
              label: hub.iot_hub_name,
              value: hub.iot_hub_name,
              expire: hub.expire_in_days,
            });
          });
        }
        setApp(app.application_id);
        setConfigs(app.device_configs);
        setConnConfigs(app.conn_configs);
        setApp_level({
          fieldName: "Application Level *",
          fieldType: "Dropdown",
          value: "",
          options: levels,
        });
        if (app.install_status) {
          setInstallationStatus({
            ...installationStatus,
            value: app.install_status[0].device_install_status_id,
            options: app.install_status.map(
              ({
                device_install_status_id: value,
                device_installation_status: label,
              }: any) => ({ value, label })
            ),
          });
        } else {
          setInstallationStatus({
            ...installationStatus,
            value: "",
            options: [],
          });
        }
        const NoIotHub = () => {
          enqueueSnackbar("No IoT Hubs present for this application.", {
            variant: "error",
          });
          setGateway({
            fieldName: "IoT Hub *",
            fieldType: "Dropdown",
            value: "",
            options: [],
          });
        };
        app.iot_hubs
          ? setGateway({
              fieldName: "IoT Hub *",
              fieldType: "Dropdown",
              value: app.iot_hubs[0].iot_hub_name,
              options: iot_hubs,
            })
          : NoIotHub();
        setConfig("");
        setTags1([]);
        setProperties([]);
        setTags([]);
      }
    });
  };

  const [dataArray, setDataArray] = React.useState([] as any);
  const [rowIndex, setRowIndex] = React.useState(0 as any);
  const [saveDisable, setSaveDisable] = React.useState(false);

  const handleChangeAddNewRow = (index: any, event: any) => {
    let temp = [...dataArray];
    temp[index] = event.target.value;
    setDataArray(temp);
  };

  const handleSubmitNewRow = () => {
    let temp = [...tags1];

    for (const i of temp) {
      if (i.tag_name === selectedTag.tag_name) {
        i.table_values.push(dataArray);
      }
    }
    setTags1(temp);
    setDataArray([]);
  };
  const handleSubmitEditRow = () => {
    let temp = [...tags1];

    for (const i of temp) {
      if (i.tag_name === selectedTag.tag_name) {
        i.table_values[rowIndex] = dataArray;
      }
    }
    setTags1(temp);
    setDataArray([]);
  };

  const [selectedConfig, setSelectedConfig] = React.useState({} as any);
  const [selectedTag, setSelectedTag] = React.useState({
    tag_name: "initial",
    tag_value: "initial",
    tag_type: "initial",
    table_values: [],
  } as any);

  const handleChangeConfig = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTags([]);
    setbackDrop(true);
    deviceConficApi(event.target.value);
  };

  const [tags, setTags] = React.useState([] as any);
  const [open, setOpen] = useState(false);
  const [openNewRow, setOpenNewRow] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);

  const [addFieldInput, setAddFieldInput] = useState([
    { fieldName: "", fieldType: "Text", value: "", options: [] },
  ]);

  const handleChangeAddNewFields = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const values = [...addFieldInput];
    values[index][event.target.name] = event.target.value;
    setAddFieldInput(values);
  };

  const handleSubmitNewFields = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const letterNumber = /^[0-9a-zA-Z_-]+$/;
    let flag = true;
    const array = [...addFieldInput];
    array.forEach((item: any) => {
      if (item.fieldName === "") {
        enqueueSnackbar("Please enter a field name", { variant: "error" });
        flag = false;
      } else if (
        !(letterNumber.test(item.fieldName) && item.fieldName.length < 50)
      ) {
        enqueueSnackbar(
          `Invalid field name (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)`,
          { variant: "error" }
        );
        flag = false;
      }
    });

    if (flag) {
      const tags1 = [...tags, ...addFieldInput];
      setTags(tags1);
      setOpen(false);
      setAddFieldInput([
        { fieldName: "", fieldType: "Text", value: "", options: [] },
      ]);
    }
  };

  const handleAddNewFields = () => {
    setAddFieldInput([
      ...addFieldInput,
      { fieldName: "", fieldType: "Text", value: "", options: [] },
    ]);
  };

  const handleDeleteNewFields = (index: number) => {
    const values = [...addFieldInput];
    values.splice(index, 1);
    setAddFieldInput(values);
  };

  const handleChange = (index: number, event: any) => {
    const temp = [...tags];

    const options = temp[index].options;
    temp[index].value = event.target.value;
    if (options && options[0]?.hasOwnProperty("expiry_days")) {
      const cValue = options.find(
        (element: any) => element.value === temp[index].value
      );
      if (
        cValue.expiry_days !== null &&
        cValue.expiry_days < EXPIRE_LIMITED_DAYES
      ) {
        temp[
          index
        ].error = `${temp[index].value} IOT hub will expire in ${cValue.expiry_days} days. This IOT Hub is in freeze time`;
        setSaveDisable(true);
      } else {
        setSaveDisable(false);
        temp[index].error = "";
      }
    }
    setTags(temp);
  };
  const { enqueueSnackbar } = useSnackbar();

  const validateField = () => {
    function testJSON(text: any) {
      if (typeof text !== "string") {
        return false;
      }
      try {
        JSON.parse(text);
        return true;
      } catch (error) {
        return false;
      }
    }

    let flag = true;

    if (app === "") {
      enqueueSnackbar("Please select Application", { variant: "error" });
      flag = false;
    }
    if (app_level.value === "") {
      enqueueSnackbar("Please select Application level", { variant: "error" });
      flag = false;
    }
    if (gateway.value === "") {
      enqueueSnackbar("Please select IoT Hub", { variant: "error" });
      flag = false;
    }
    if (connConfig === "" && checked) {
      enqueueSnackbar("Please select connection configuration", {
        variant: "error",
      });
      flag = false;
    } else if (connConfig && checked) {
      if (selectedConnConfig.metadata.config_type === "LORIOT") {
        if (ltitle === "") {
          enqueueSnackbar("Please enter device title for LORIOT", {
            variant: "error",
          });
          flag = false;
        } else if (ldesc === "") {
          enqueueSnackbar("Please enter device description for LORIOT", {
            variant: "error",
          });
          flag = false;
        } else if (devclass === "") {
          enqueueSnackbar("Please select device class", { variant: "error" });
          flag = false;
        } else if (appkey === "" || !tthex.test(appkey)) {
          enqueueSnackbar("Please enter valid App Key (32 character HEX)", {
            variant: "error",
          });
          flag = false;
        } else if (appeui === "" || !sthex.test(appeui)) {
          enqueueSnackbar("Please enter valid App EUI (16 character HEX)", {
            variant: "error",
          });
          flag = false;
        } else if (deveui === "" || !sthex.test(deveui)) {
          enqueueSnackbar("Please enter valid Dev EUI (16 character HEX)", {
            variant: "error",
          });
          flag = false;
        } else if (deveui.toUpperCase() !== deveui) {
          enqueueSnackbar("Please enter Dev EUI in upper case", {
            variant: "error",
          });
          flag = false;
        }
      }
    }
    // if (selectedConfig === {}) {
    //   enqueueSnackbar("Please select configuration", { variant: "error" });
    //   flag = false;
    // } else
    if (!(letterNumberID.test(tags[0].value) && tags[0].value.length <= 64)) {
      enqueueSnackbar(
        "Invalid Device Id (only 0-9,A-Z,a-z,_,- allowed upto 64 characters)",
        { variant: "error" }
      );
      flag = false;
    } else if (
      !(letterNumber.test(tags[1].value) && tags[1].value.length <= 50)
    ) {
      enqueueSnackbar(
        "Invalid Device Name (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)",
        { variant: "error" }
      );
      flag = false;
    } else if (
      !(letterNumber.test(tags[2].value) && tags[2].value.length <= 50)
    ) {
      enqueueSnackbar(
        "Invalid Serial (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)",
        { variant: "error" }
      );
      flag = false;
    }

    //installationStatus id dynamic field
    const customTagStartIndex = installationStatus.options.length > 0 ? 6 : 5;

    //TODO:We are validating custom fields here. The name tag is confusing. Refactor
    for (let i = customTagStartIndex; i < tags.length; i++) {
      if (!(letterNumber.test(tags[i].value) && tags[i].value.length <= 50)) {
        enqueueSnackbar(
          `Invalid ${tags[i].fieldName} (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)`,
          { variant: "error" }
        );
        flag = false;
      }
    }

    const tagValidationError = validateTags(tags1);
    if (tagValidationError) {
      enqueueSnackbar(tagValidationError, { variant: "error" });
      flag = false;
    }

    if (flag) {
      saveDeviceAction();
    }
  };

  const saveDeviceAction = () => {
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    let rawdata = {};
    if (connConfig && checked) {
      if (selectedConnConfig.metadata.config_type === "LORIOT") {
        rawdata["title"] = ltitle;
        rawdata["description"] = ldesc;
        rawdata["devclass"] = devclass;
        rawdata["appkey"] = appkey;
        rawdata["deveui"] = deveui;
        rawdata["appeui"] = appeui;
      }
    }

    const propps = [] as any;
    const propp = {} as any;
    properties.forEach((property: any) => {
      propp[`${property.prop_name}`] = property.prop_value;
    });
    propps.push(propp);

    const taggs = [] as any;
    const tagg = {} as any;
    tags1.forEach((tag: any) => {
      tagg[`${tag.tag_name}`] =
        tag.tag_type === "Tabular"
          ? {
              type: tag.tag_type,
              value: tag.tag_value,
              table_values: tag.table_values,
            }
          : tag.tag_type === "Dropdown"
          ? {
              type: tag.tag_type,
              value: tag.tag_value,
              data_type: tag.tag_data_type,
              options: tag.options,
            }
          : {
              type: tag.tag_type,
              value: tag.tag_value,
              data_type: tag.tag_data_type,
            };
    });
    taggs.push(tagg);

    const fields = {} as any;
    //TODO: Refactor.  We are mixing custom fields with static fields :(
    const customFieldStartIndex = installationStatus.options.length > 0 ? 6 : 5;
    const tags_temp = tags.slice(customFieldStartIndex);
    tags_temp.forEach((tag: any) => {
      fields[`${tag.fieldName}`] = tag.value;
    });
    setbackDrop(true);
    const DEVICES_API = `${API["GETALLAPPLS"]}/${app}/devices`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken.access_token}`,
        "Ocp-Apim-Subscription-Key": `${API_KEY}`,
        "Ocp-Apim-Trace": `true`,
      },
      body:
        connConfig && checked
          ? JSON.stringify({
              pm_s_id: pmSessionId,
              pm_s_token: pmSessionToken,
              device: {
                application_level_id: tags[4].value,
                device_id: tags[0].value,
                device_name: tags[1].value,
                serial_num: tags[2].value,
                device_config_id: config,
                device_config: {
                  config_name: selectedConfig.config_name,
                  config_desc: selectedConfig.config_type,
                  device_type: selectedConfig.device_type,
                  auth_type: selectedConfig.auth_type,
                  edge_type: selectedConfig.edge_type,
                  add_to_iot_hub: "y",
                  properties: propp,
                  tags: tagg,
                },
                cloud_gateway: tags[3].value,
                device_install_status_id:
                  installationStatus.options.length > 0
                    ? tags[5]?.value || DEFAULT_INSTALL_STATUS_VALUE
                    : DEFAULT_INSTALL_STATUS_VALUE,
                fields: fields,
                conn_config: selectedConnConfig,
                conn_config_id: connConfig,
                conn_request_payload: rawdata,
              },
            })
          : JSON.stringify({
              pm_s_id: pmSessionId,
              pm_s_token: pmSessionToken,
              device: {
                application_level_id: tags[4].value,
                device_id: tags[0].value,
                device_name: tags[1].value,
                serial_num: tags[2].value,
                device_config_id: config,
                device_config: {
                  config_name: selectedConfig.config_name,
                  config_desc: selectedConfig.config_type,
                  device_type: selectedConfig.device_type,
                  auth_type: selectedConfig.auth_type,
                  edge_type: selectedConfig.edge_type,
                  add_to_iot_hub: "y",
                  properties: propp,
                  tags: tagg,
                },
                cloud_gateway: tags[3].value,
                device_install_status_id:
                  installationStatus.options.length > 0
                    ? tags[5]?.value || DEFAULT_INSTALL_STATUS_VALUE
                    : DEFAULT_INSTALL_STATUS_VALUE,
                fields: fields,
              },
            }),
    };
    setbackDrop(true);
    fetch(DEVICES_API, requestOptions)
      .then(function (response: any) {
        response.json().then((response: any) => {
          setbackDrop(false);
          if (
            response &&
            response.status === 401 &&
            response &&
            arrayOfErrorsRefresh.includes(response.message.toLowerCase())
          ) {
            try {
              const tokenKey = "sso_token";
              setbackDrop(true);
              callXhrRequest()
                .then(function (data) {
                  sessionStorage.setItem(tokenKey, data);
                  setbackDrop(false);
                  saveDeviceAction();
                })
                .catch(function (error) {
                  user.triggerLogout();
                  console.log("error", error);
                });
            } catch (error) {
              console.log(error);
            }
            return;
          } else if (
            response &&
            response.status === 401 &&
            response &&
            arrayOfErrorsLogout.includes(response.message.toLowerCase())
          ) {
            try {
              user.triggerLogout();
            } catch (error) {
              console.log(error);
            }
            return;
          } else if (response.status === 200 || response.status === 201) {
            setbackDrop(false);
            const deviceDlmId = response.device_dlm_id;
            queueJob({
              id: deviceDlmId,
              type: JOB_TYPE.SINGLE_DEVICE_CREATION,
              completed: false, //TODO: This can be set to false by default
              timeStamp: Date.now(),
              payload: { deviceDlmId, applicationId: app },
            });
            enqueueSnackbar("Device creation initiated", {
              variant: "success",
            });
            cancelDeviceAction();
          } else if (response.status === 500) {
            enqueueSnackbar(response.message, { variant: "error" });
            setbackDrop(false);
          } else {
            enqueueSnackbar("Device creation failed", { variant: "error" });
            setbackDrop(false);
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const cancelDeviceAction = () => {
    setTimeout(function () {
      setConfig("");
      setApp("");
      setTags([]);
      setLtitle("");
      setLdesc("");
      setDevclass("");
      setAppkey("");
      setDeveui("");
      setAppeui("");
      props.returnHome();
    }, 500);
  };

  const handleChangeTags = (tagName: any, event: any) => {
    const temp = [...tags1];
    temp.forEach((tag: any) => {
      if (tag.tag_name === tagName) {
        tag.tag_value = event.target.value;
      }
    });
    setTags1(temp);
  };

  const handleChangeProps = (propName: any, event: any) => {
    const temp = [...properties];
    temp.forEach((prop: any) => {
      if (prop.prop_name === propName) {
        prop.prop_value = event.target.value;
      }
    });
    setProperties(temp);
  };

  useEffect(() => {
    if (connConfig) {
      const deviceIdEntered = tags[0]?.value;
      setDeveui(deviceIdEntered);
      setLtitle(deviceIdEntered);
      setLdesc(deviceIdEntered);
    }
  }, [tags[0]?.value]); //TODO: 0 index for DeviceId. Refactor implementation

  useEffect(() => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const GET_DEVICES_INPUTS = API["GETDEVICESINPUTS"];
    Promise.all([
      fetch(
        GET_DEVICES_INPUTS +
          `?pm_s_id=${pmSessionId}&pm_s_token=${pmSessionToken}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.access_token}`,
            "Ocp-Apim-Subscription-Key": `${API_KEY}`,
            "Ocp-Apim-Trace": `true`,
          },
        }
      ),
    ])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (response) {
        if (
          response &&
          response[0].status === 401 &&
          response &&
          arrayOfErrorsRefresh.includes(response[0].message.toLowerCase())
        ) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest()
              .then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                setReload(!reload);
              })
              .catch(function (error) {
                user.triggerLogout();
                console.log("error", error);
              });
          } catch (error) {
            console.log(error);
          }
          return;
        } else if (
          response &&
          response[0].status === 401 &&
          response &&
          arrayOfErrorsLogout.includes(response[0].message.toLowerCase())
        ) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        setApplication(response && response[0].devices_input[0].applications);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [reload]);

  const deviceConficApi = (confVal: any) => {
    const temp1 = [deviceID, deviceName, serial, gateway, app_level];
    setConfig(confVal);
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const GET_DEVICES_CONFIG = API["GETALLAPPLS"];
    Promise.all([
      fetch(
        GET_DEVICES_CONFIG +
          `/${app}/device-config/${confVal}?pm_s_id=${pmSessionId}&pm_s_token=${pmSessionToken}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.access_token}`,
            "Ocp-Apim-Subscription-Key": `${API_KEY}`,
            "Ocp-Apim-Trace": `true`,
          },
        }
      ),
    ])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (response: any) {
        if (
          response &&
          response[0].status === 401 &&
          response &&
          arrayOfErrorsRefresh.includes(response[0].message.toLowerCase())
        ) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest()
              .then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                deviceConficApi(confVal);
                setbackDrop(false);
              })
              .catch(function (error) {
                user.triggerLogout();
                console.log("error", error);
                setbackDrop(false);
              });
          } catch (error) {
            console.log(error);
            setbackDrop(false);
          }
          return;
        } else if (
          response &&
          response[0].status === 401 &&
          response &&
          arrayOfErrorsLogout.includes(response[0].message.toLowerCase())
        ) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          setbackDrop(false);
          return;
        }
        const iot_hubs = [] as any;
        if (response[0].az_iot_hubs) {
          response[0].az_iot_hubs.forEach((hub: any) => {
            iot_hubs.push({
              label: hub.iot_hub_name,
              value: hub.iot_hub_name,
              expiry_days: hub.expiry_days,
            });
          });
        }
        const varIoTHubGetway = {
          fieldName: "IoT Hub *",
          fieldType: "Dropdown",
          value: "",
          options: iot_hubs,
        };
        const NoIotHub = () => {
          enqueueSnackbar("No IoT Hubs present for this application.", {
            variant: "error",
          });
          setGateway({
            fieldName: "IoT Hub *",
            fieldType: "Dropdown",
            value: "",
            options: [],
          });
        };
        iot_hubs ? setGateway(varIoTHubGetway) : NoIotHub();

        configs.forEach((config: any) => {
          if (config.device_config_id === confVal) {
            setSelectedConfig(config);
            setDropdown(config.dropdown);
            const temp: any[] = [];
            for (const tag in config.tags) {
              if (config.tags[tag].type === "Tabular") {
                temp.push({
                  tag_name: tag,
                  tag_value: config.tags[tag].value,
                  tag_type: config.tags[tag].type,
                  table_values: [],
                });
              } else if (config.tags[tag].type === "Text") {
                temp.push({
                  tag_name: tag,
                  tag_value: config.tags[tag].value,
                  tag_type: config.tags[tag].type,
                  tag_data_type: config.tags[tag].data_type,
                });
              } else if (config.tags[tag].type === "Dropdown") {
                temp.push({
                  tag_name: tag,
                  tag_value: config.tags[tag].value,
                  tag_type: config.tags[tag].type,
                  options: config.tags[tag].options,
                  tag_data_type: config.tags[tag].data_type,
                });
              }
            }
            setTags1(temp);

            const temp2: any[] = [];
            for (const prop in config.properties) {
              temp2.push({
                prop_name: prop,
                prop_value: config.properties[prop],
              });
            }
            setProperties(temp2);
          }
        }); //id

        const temp1 = [
          deviceID,
          deviceName,
          serial,
          varIoTHubGetway,
          app_level,
        ];

        if (installationStatus.options.length > 0) {
          temp1.push(installationStatus);
        }

        setTags(temp1);
        setbackDrop(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.subtitle}>
        All fields marked * are mandatory
      </Typography>

      <form className={classes.form} noValidate method="post">
        <div>
          <Grid container>
            <Grid item xs={6} className={classes.spaceRight}>
              <div className={classes.fieldWrapper}>
                <InputLabel
                  classes={{ root: classes.labels }}
                  htmlFor="selectApplication"
                >
                  Select Application *
                </InputLabel>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  select
                  variant="outlined"
                  classes={{ root: classes.fieldRoot }}
                  inputProps={{ className: classes.field }}
                  value={app}
                  id="selectApplication"
                  SelectProps={{
                    classes: {
                      iconOutlined: classes.icon,
                    },
                  }}
                  onChange={handleChangeApp}
                >
                  {application.map((option: any) => (
                    <MenuItem
                      className={classes.options}
                      key={option.application_id}
                      value={option.application_id}
                    >
                      {option.application_name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.spaceLeft}>
              <div className={classes.fieldWrapper}>
                <InputLabel
                  classes={{ root: classes.labels }}
                  htmlFor="Device Configuration"
                >
                  Device Configuration *
                </InputLabel>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  select
                  variant="outlined"
                  classes={{ root: classes.fieldRoot }}
                  inputProps={{ className: classes.field }}
                  value={config}
                  id="selectConfiguration"
                  SelectProps={{
                    classes: {
                      iconOutlined: classes.icon,
                    },
                  }}
                  onChange={handleChangeConfig}
                >
                  {configs
                    ? configs.map((option: any) => (
                        <MenuItem
                          className={classes.options}
                          key={option.device_config_id}
                          value={option.device_config_id}
                        >
                          {option.config_name}
                        </MenuItem>
                      ))
                    : []}
                </TextField>
              </div>
            </Grid>
            {config ? (
              <Grid
                container
                xs={12}
                direction="row"
                justify="flex-start"
                spacing={5}
                alignItems="center"
                className={classes.topGrid}
              >
                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Typography className={classes.gridVar}>
                      Device Type
                    </Typography>
                    <Typography className={classes.gridVal}>
                      {selectedConfig.device_type}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Typography className={classes.gridVar}>
                      Edge Type
                    </Typography>
                    <Typography className={classes.gridVal}>
                      {selectedConfig.edge_type}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Typography className={classes.gridVar}>
                      Authentication Type
                    </Typography>
                    <Typography className={classes.gridVal}>
                      {selectedConfig.auth_type}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <div></div>
            )}

            {tags.map((tag: any, index: any) => {
              if (tag.fieldType === "Text") {
                return (
                  <Grid
                    item
                    xs={6}
                    className={
                      index % 2 === 1 ? classes.spaceLeft : classes.spaceRight
                    }
                  >
                    <CssTextField
                      fullWidth
                      name={tag.fieldName}
                      label={tag.fieldName}
                      type={tag.fieldType}
                      id={tag.fieldName}
                      className={classes.fieldWrapper}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labels,
                      }}
                      onChange={(event) => handleChange(index, event)}
                      value={tag.value}
                    />
                  </Grid>
                );
              } else if (tag.fieldType === "Dropdown") {
                return (
                  <Grid
                    item
                    xs={6}
                    className={
                      index % 2 === 1 ? classes.spaceLeft : classes.spaceRight
                    }
                  >
                    <div className={classes.fieldWrapper}>
                      <InputLabel
                        classes={{ root: classes.labels }}
                        htmlFor={tag.fieldName}
                      >
                        {tag.fieldName}
                      </InputLabel>
                      <TextField
                        className={classes.textfield}
                        fullWidth
                        select
                        variant="outlined"
                        classes={{ root: classes.fieldRoot }}
                        inputProps={{ className: classes.field }}
                        value={tag.value}
                        id={tag.fieldName}
                        SelectProps={{
                          classes: {
                            /* selectMenu : classes.selectRoot, */
                            iconOutlined: classes.icon,
                          },
                        }}
                        onChange={(event) => handleChange(index, event)}
                      >
                        {tag.options.map((option: any) => (
                          <MenuItem
                            className={classes.options}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      {tag.error && (
                        <p style={{ color: "#D72B3F", fontSize: 12 }}>
                          {tag.error}
                        </p>
                      )}
                    </div>
                  </Grid>
                );
              } else return null;
            })}
          </Grid>
          <Button onClick={() => setOpen(true)}>
            <Typography className={classes.addNewFieldText}>
              Add New Field
            </Typography>
          </Button>
          {config ? (
            <div>
              <Grid item xs={12}>
                <List
                  dense={true}
                  classes={{ root: classes.noSpace }}
                  className={classes.spaceTop}
                >
                  <ListItem classes={{ root: classes.noSpace }}>
                    <ListItemText
                      classes={{ root: classes.noSpace }}
                      primary="Tags"
                      primaryTypographyProps={{
                        style: {
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          fontSize: "16px",
                        },
                      }}
                      secondaryTypographyProps={{
                        style: {
                          color: "#FCFCFC",
                          fontSize: "12px",
                          marginTop: "10px",
                        },
                      }}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid
                container
                xs={12}
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.topGrid1}
              >
                {tags1.map((tag: any, index: any) => {
                  if (tag.tag_type === "Text") {
                    return (
                      <Grid
                        item
                        xs={6}
                        style={{ marginTop: "16px" }}
                        className={classes.spaceRight}
                      >
                        <InputLabel
                          style={{ marginTop: "1em" }}
                          classes={{ root: classes.labels }}
                          htmlFor={tag.tag_name}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                          >
                            {tag.tag_name}
                            {/* <span> //on hold for data fix
                              {tag.tag_data_type && (
                                <span
                                  style={{
                                    fontSize: ".8em",
                                    marginLeft: ".4em",
                                  }}
                                >
                                  {`(${tag.tag_data_type})`}
                                </span>
                              )}
                           </span> */}
                            <div>
                              <DeleteIcon
                                onClick={() => {
                                  setTags1(
                                    [...tags1].filter(
                                      (item: any) => item !== tag
                                    )
                                  );
                                }}
                                style={{ marginLeft: "1em" }}
                                fontSize="small"
                              ></DeleteIcon>
                            </div>
                          </Grid>
                        </InputLabel>
                        <CssTextFieldVariable
                          fullWidth
                          name={tag.tag_name}
                          type="Text"
                          id={tag.tag_name}
                          style={{ marginTop: "16px" }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.labels,
                          }}
                          value={tag.tag_value}
                          onChange={(event: any) =>
                            handleChangeTags(tag.tag_name, event)
                          }
                        />
                      </Grid>
                    );
                  } else if (tag.tag_type === "Dropdown") {
                    return (
                      <Grid
                        item
                        xs={6}
                        style={{ marginTop: "16px" }}
                        className={classes.spaceRight}
                      >
                        <InputLabel
                          style={{ marginTop: "1em" }}
                          classes={{ root: classes.labels }}
                          htmlFor={tag.tag_name}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                          >
                            {tag.tag_name}
                            <div>
                              <DeleteIcon
                                onClick={() => {
                                  setTags1(
                                    [...tags1].filter(
                                      (item: any) => item !== tag
                                    )
                                  );
                                }}
                                style={{ marginLeft: "1em" }}
                                fontSize="small"
                              ></DeleteIcon>
                            </div>
                          </Grid>
                        </InputLabel>
                        <CssTextFieldVariable
                          fullWidth
                          select
                          variant="outlined"
                          inputProps={{ className: classes.field }}
                          style={{ marginTop: "16px" }}
                          id="selectApplication"
                          SelectProps={{
                            classes: {
                              iconOutlined: classes.icon,
                            },
                          }}
                          value={tag.tag_value}
                          onChange={(event: any) =>
                            handleChangeTags(tag.tag_name, event)
                          }
                        >
                          {tag.options.split("|").map((option: any) => (
                            <MenuItem
                              className={classes.options}
                              key={option}
                              value={option}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </CssTextFieldVariable>
                      </Grid>
                    );
                  }
                })}
                {tags1.map((tag: any, index: any) => {
                  if (tag.tag_type === "Tabular") {
                    return (
                      <Grid
                        item
                        xs={12}
                        style={{ marginTop: "20px", marginBottom: "5px" }}
                      >
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                        >
                          <Typography className={classes.labels}>
                            {tag.tag_name}
                          </Typography>

                          <DeleteIcon
                            onClick={() => {
                              setTags1(
                                [...tags1].filter((item: any) => item !== tag)
                              );
                            }}
                            style={{ marginLeft: "1em" }}
                            fontSize="small"
                          ></DeleteIcon>
                        </Grid>

                        <TableContainer
                          component={Paper}
                          className={classes.tableWrapper}
                        >
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                {tag.tag_value
                                  .split("|")
                                  .map((value: any, index: any) => {
                                    return (
                                      <StyledTableCell>
                                        <Grid container direction="row">
                                          <Grid item>
                                            <Typography
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {value}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </StyledTableCell>
                                    );
                                  })}
                                <StyledTableCell style={{ width: "1em" }}>
                                  &nbsp;
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {tag.table_values.map(
                                (value: any, index: any) => {
                                  return (
                                    <StyledTableRow>
                                      {value.map((item: any, index: any) => {
                                        return (
                                          <StyledTableCell>
                                            {item}
                                          </StyledTableCell>
                                        );
                                      })}
                                      <StyledTableCell style={{ width: "1em" }}>
                                        <IconButton
                                          onClick={() => {
                                            setOpenEditRow(true);
                                            setSelectedTag(tag);
                                            setRowIndex(index);
                                            let array = [];
                                            for (const i of value) {
                                              array.push(i);
                                            }
                                            setDataArray(array);
                                          }}
                                          classes={{
                                            root: classes.menuButtonRoot,
                                          }}
                                        >
                                          <EditIcon color="secondary" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                }
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Button
                          onClick={() => {
                            setOpenNewRow(true);
                            setSelectedTag(tag);
                            let array = [];
                            for (const i of tag.tag_value.split("|")) {
                              array.push("");
                            }
                            setDataArray(array);
                          }}
                        >
                          <Typography className={classes.addNewFieldText}>
                            Add New Row
                          </Typography>
                        </Button>
                      </Grid>
                    );
                  }
                })}
              </Grid>

              <Grid item xs={12}>
                <List
                  dense={true}
                  classes={{ root: classes.noSpace }}
                  className={classes.spaceTop}
                >
                  <ListItem classes={{ root: classes.noSpace }}>
                    <ListItemText
                      classes={{ root: classes.noSpace }}
                      primary="Properties"
                      primaryTypographyProps={{
                        style: {
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          fontSize: "16px",
                        },
                      }}
                      secondaryTypographyProps={{
                        style: {
                          color: "#FCFCFC",
                          fontSize: "12px",
                          marginTop: "10px",
                        },
                      }}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid
                container
                xs={12}
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.topGrid1}
              >
                {properties.map((prop: any, index: any) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      style={{ marginTop: "16px" }}
                      className={classes.spaceRight}
                    >
                      <InputLabel
                        style={{ marginTop: "1em" }}
                        classes={{ root: classes.labels }}
                        htmlFor={prop.prop_name}
                      >
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                        >
                          {prop.prop_name}
                          <div>
                            <DeleteIcon
                              onClick={() => {
                                setProperties(
                                  [...properties].filter(
                                    (item: any) => item !== prop
                                  )
                                );
                              }}
                              style={{ marginLeft: "1em" }}
                              fontSize="small"
                            ></DeleteIcon>
                          </div>
                        </Grid>
                      </InputLabel>
                      <CssTextFieldVariable
                        fullWidth
                        name={prop.prop_name}
                        type="Text"
                        id={prop.prop_name}
                        style={{ marginTop: "16px" }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labels,
                        }}
                        value={prop.prop_value}
                        onChange={(event: any) =>
                          handleChangeProps(prop.prop_name, event)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          ) : (
            <div></div>
          )}

          <Dialog
            fullWidth
            classes={{ paper: classes.dialogRoot }}
            onClose={() => setOpen(false)}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={() => setOpen(false)}
            >
              Add New Field
            </DialogTitle>
            <DialogContent style={{ overflowX: "hidden", overflowY: "auto" }}>
              <form onSubmit={handleSubmitNewFields}>
                {addFieldInput.map((inputField, index) => (
                  <div>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      alignItems="center"
                      justify="space-around"
                    >
                      <Grid item xs={4} className={classes.spaceLeft}>
                        <CssTextField
                          fullWidth
                          name="fieldName"
                          label="Field Name *"
                          type="fieldName"
                          id="fieldName"
                          className={classes.fieldWrapper}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.labels,
                          }}
                          onChange={(event) =>
                            handleChangeAddNewFields(index, event)
                          }
                          value={inputField.fieldName}
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.spaceLeft}>
                        <div className={classes.fieldWrapper}>
                          <InputLabel
                            classes={{ root: classes.labels }}
                            htmlFor="fieldType"
                          >
                            Field Type *
                          </InputLabel>
                          <TextField
                            className={classes.textfield}
                            fullWidth
                            select
                            variant="outlined"
                            name="fieldType"
                            classes={{ root: classes.fieldRoot }}
                            inputProps={{ className: classes.field }}
                            value={inputField.fieldType}
                            id="fieldType"
                            SelectProps={{
                              classes: {
                                /* selectMenu : classes.selectRoot, */
                                iconOutlined: classes.icon,
                              },
                            }}
                            onChange={(event) =>
                              handleChangeAddNewFields(index, event)
                            }
                          >
                            {newFieldOptions.map((option: any) => (
                              <MenuItem
                                className={classes.options}
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </Grid>
                      <Grid item xs={2}>
                        <Button onClick={() => handleDeleteNewFields(index)}>
                          <DeleteIcon style={{ color: "#FFFFFF" }}></DeleteIcon>
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                ))}
                <Button onClick={() => handleAddNewFields()}>
                  <Typography className={classes.buttonText}>
                    Add New Field
                  </Typography>
                </Button>
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpen(false)}
                variant="outlined"
                className="dcBtDelete"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmitNewFields}
                variant="contained"
                className="dcBtEdit"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            fullWidth
            classes={{ paper: classes.dialogRoot }}
            onClose={() => setOpenNewRow(false)}
            aria-labelledby="customized-dialog-title"
            open={openNewRow}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={() => setOpenNewRow(false)}
            >
              Add New Row
            </DialogTitle>
            <DialogContent style={{ overflow: "hidden" }}>
              <form onSubmit={handleSubmitNewRow}>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  alignItems="center"
                  justify="space-around"
                >
                  {selectedTag.tag_value
                    .split("|")
                    .map((value: any, index: any) => {
                      if (
                        dropdown[`${selectedTag.tag_name}`] &&
                        dropdown[`${selectedTag.tag_name}`][`${value}`]
                      ) {
                        return (
                          <Grid item xs={4} className={classes.spaceLeft}>
                            <div className={classes.fieldWrapper}>
                              <InputLabel classes={{ root: classes.labels }}>
                                {value}
                              </InputLabel>
                              <TextField
                                className={classes.textfield}
                                fullWidth
                                select
                                variant="outlined"
                                classes={{ root: classes.fieldRoot }}
                                inputProps={{ className: classes.field }}
                                value={dataArray[index]}
                                id={value}
                                SelectProps={{
                                  classes: {
                                    iconOutlined: classes.icon,
                                  },
                                }}
                                onChange={(event) =>
                                  handleChangeAddNewRow(index, event)
                                }
                              >
                                {dropdown[`${selectedTag.tag_name}`][`${value}`]
                                  .split("|")
                                  .map((option: any) => (
                                    <MenuItem
                                      className={classes.options}
                                      key={option}
                                      value={option}
                                    >
                                      {option}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </div>
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid item xs={4} className={classes.spaceLeft}>
                            <CssTextField
                              fullWidth
                              name={value}
                              label={value}
                              type={value}
                              id={value}
                              className={classes.fieldWrapper}
                              InputProps={{
                                disableUnderline: true,
                              }}
                              InputLabelProps={{
                                shrink: true,
                                className: classes.labels,
                              }}
                              onChange={(event) =>
                                handleChangeAddNewRow(index, event)
                              }
                              value={dataArray[index]}
                            />
                          </Grid>
                        );
                      }

                      //else dropdown
                    })}
                </Grid>
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenNewRow(false)}
                variant="outlined"
                className="dcBtDelete"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleSubmitNewRow();
                  setOpenNewRow(false);
                }}
                variant="contained"
                className="dcBtEdit"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            classes={{ paper: classes.dialogRoot }}
            onClose={() => setOpenEditRow(false)}
            aria-labelledby="customized-dialog-title"
            open={openEditRow}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={() => setOpenEditRow(false)}
            >
              Edit Row
            </DialogTitle>
            <DialogContent style={{ overflow: "hidden" }}>
              <form onSubmit={handleSubmitEditRow}>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  alignItems="center"
                  justify="space-around"
                >
                  {selectedTag.tag_value
                    .split("|")
                    .map((value: any, index: any) => {
                      if (
                        dropdown[`${selectedTag.tag_name}`] &&
                        dropdown[`${selectedTag.tag_name}`][`${value}`]
                      ) {
                        return (
                          <Grid item xs={4} className={classes.spaceLeft}>
                            <div className={classes.fieldWrapper}>
                              <InputLabel classes={{ root: classes.labels }}>
                                {value}
                              </InputLabel>
                              <TextField
                                className={classes.textfield}
                                fullWidth
                                select
                                variant="outlined"
                                classes={{ root: classes.fieldRoot }}
                                inputProps={{ className: classes.field }}
                                value={dataArray[index]}
                                id={value}
                                SelectProps={{
                                  classes: {
                                    iconOutlined: classes.icon,
                                  },
                                }}
                                onChange={(event) =>
                                  handleChangeAddNewRow(index, event)
                                }
                              >
                                {dropdown[`${selectedTag.tag_name}`][`${value}`]
                                  .split("|")
                                  .map((option: any) => (
                                    <MenuItem
                                      className={classes.options}
                                      key={option}
                                      value={option}
                                    >
                                      {option}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </div>
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid item xs={4} className={classes.spaceLeft}>
                            <CssTextField
                              fullWidth
                              name={value}
                              label={value}
                              type={value}
                              id={value}
                              className={classes.fieldWrapper}
                              InputProps={{
                                disableUnderline: true,
                              }}
                              InputLabelProps={{
                                shrink: true,
                                className: classes.labels,
                              }}
                              onChange={(event) =>
                                handleChangeAddNewRow(index, event)
                              }
                              value={dataArray[index]}
                            />
                          </Grid>
                        );
                      }

                      //else dropdown
                    })}
                </Grid>
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenEditRow(false)}
                variant="outlined"
                className="dcBtDelete"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleSubmitEditRow();
                  setOpenEditRow(false);
                }}
                variant="contained"
                className="dcBtEdit"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <div className={classes.fieldWrapper}>
            <Grid container direction="column">
              <FormControlLabel
                classes={{
                  root: classes.checkboxRoot,
                  label: classes.checkboxLabel,
                }}
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChangeChecked}
                    name="checked"
                    color="primary"
                    className={classes.bgColorBlue}
                  />
                }
                label="Add a Connection Configuration"
              />

              {checked ? (
                <div>
                  <Grid item xs={6} className={classes.spaceRight}>
                    <InputLabel
                      classes={{ root: classes.labels }}
                      htmlFor="ConnConfiguration"
                    >
                      Connection Configuration *
                    </InputLabel>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      select
                      variant="outlined"
                      classes={{ root: classes.fieldRoot }}
                      inputProps={{ className: classes.field }}
                      value={connConfig}
                      id="selectConfiguration"
                      SelectProps={{
                        classes: {
                          /* selectMenu : classes.selectRoot, */
                          iconOutlined: classes.icon,
                        },
                      }}
                      onChange={handleChangeConnConfig}
                    >
                      {connConfigs
                        ? connConfigs.map((option: any) => (
                            <MenuItem
                              className={classes.options}
                              key={option.conn_config_id}
                              value={option.conn_config_id}
                            >
                              {option.config_name}
                            </MenuItem>
                          ))
                        : []}
                    </TextField>
                  </Grid>
                  {connConfig ? (
                    selectedConnConfig.metadata.config_type === "LORIOT" ? (
                      <div>
                        <Grid item xs={6} className={classes.spaceRight}>
                          <CssTextField
                            fullWidth
                            name="ltitle"
                            label="Device Title *"
                            type="Text"
                            id="ltitle"
                            className={classes.fieldWrapper}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                              className: classes.labels,
                            }}
                            onChange={(event) => handleChangeLtitle(event)}
                            value={ltitle}
                          />
                        </Grid>
                        <Grid item xs={6} className={classes.spaceRight}>
                          <CssTextField
                            fullWidth
                            name="ldesc"
                            label="Device Description *"
                            type="Text"
                            id="ldesc"
                            className={classes.fieldWrapper}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                              className: classes.labels,
                            }}
                            onChange={(event) => handleChangeLdesc(event)}
                            value={ldesc}
                          />
                        </Grid>
                        <Grid item xs={6} className={classes.spaceRight}>
                          <div className={classes.fieldWrapper}>
                            <InputLabel
                              classes={{ root: classes.labels }}
                              htmlFor="selectClass"
                            >
                              Class *
                            </InputLabel>
                            <TextField
                              className={classes.textfield}
                              fullWidth
                              select
                              variant="outlined"
                              classes={{ root: classes.fieldRoot }}
                              inputProps={{ className: classes.field }}
                              id="selectClass"
                              SelectProps={{
                                classes: {
                                  /* selectMenu : classes.selectRoot, */
                                  iconOutlined: classes.icon,
                                },
                              }}
                              onChange={(event) => handleChangeDevclass(event)}
                              value={devclass}
                            >
                              {["A", "B", "C", "D"].map((option: any) => (
                                <MenuItem
                                  className={classes.options}
                                  key={option}
                                  value={option}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        </Grid>
                        <Grid item xs={6} className={classes.spaceRight}>
                          <CssTextField
                            fullWidth
                            name="appkey"
                            label="App Key *"
                            type="Text"
                            id="appkey"
                            className={classes.fieldWrapper}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                              className: classes.labels,
                            }}
                            onChange={(event) => handleChangeAppkey(event)}
                            value={appkey}
                          />
                        </Grid>
                        <Grid item xs={6} className={classes.spaceRight}>
                          <CustomTextField
                            style={{ margin: "16px 0 0 0px" }}
                            name="deveui"
                            label="Device EUI *"
                            readOnly
                            value={deveui}
                          />
                        </Grid>
                        <Grid item xs={6} className={classes.spaceRight}>
                          <CssTextField
                            fullWidth
                            name="appeui"
                            label="App EUI *"
                            type="Text"
                            id="appeui"
                            className={classes.fieldWrapper}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                              className: classes.labels,
                            }}
                            onChange={(event) => handleChangeAppeui(event)}
                            value={appeui}
                          />
                        </Grid>
                      </div>
                    ) : (
                      <div></div>
                    )
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </Grid>
          </div>
        </div>
        <div className={`${classes.space2Top} ${classes.bottomSection}`}>
          <Button
            variant="outlined"
            className={classes.btnCancel}
            onClick={cancelDeviceAction}
          >
            Cancel
          </Button>
          <Button
            disabled={saveDisable}
            variant="contained"
            className={classes.btnAddNDevice}
            onClick={validateField}
          >
            Save Device
          </Button>
        </div>
        <Backdrop
          className={classes.backdrop}
          open={backDrop}
          onClick={backDropClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </div>
  );
};

export default function AddNewDevice(props: any) {
  return <AddNewDeviceWrapper returnHome={props.returnHome} />;
}
